import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        pasive: "#676767",
        primary: "#1D7DEF",
        light_primary: "#1D7DEF",
        dark_primary: "#245A7C",
        secondary: "#1f2937",
        light_secondary: "#95A1AD",
        accent: "#1f2937",
        error: "#FF3B3F",
        light_error: "#EF5350",
        info: "#2196F3",
        success: "#2EB6B2",
        warning: "#FFC107",
        light: "#FFFAFA",
        dark_grey: "#424242",
        contrast_white: "#f5f5f5",
        disable: "#B0BEC5",
        xumed_light: "#ededed",
        xumed_grey: "#676767",
        vuetifyMoneyBackground: "#ffffff",
        toolbar: "#fcfcfc",
        xumed_purple: "#4a4fca",
      },
      dark: {
        pasive: "#bcbcbc",
        primary: "#1a71db",
        light_primary: "#2587fa",
        secondary: "#f7f5f5",
        light_secondary: "#95A1AD",
        accent: "#f2f2f2",
        error: "#FF3B3F",
        light_error: "#EF5350",
        info: "#2196F3",
        success: "#169490",
        warning: "#FFC107",
        light: "#FFFAFA",
        dark_grey: "#424242",
        contrast_white: "#f5f5f5",
        xumed_light: "#424242",
        xumed_grey: "#fcfcfc",
        vuetifyMoneyBackground: "#1f1f1f",
        toolbar: "#1f1f1f",
        xumed_purple: "#4a4fca",
      },
    },
  },
});
