<template>
  <v-snackbar
    v-model="show"
    :timeout="alert.timeout ? alert.timeout : '3000'"
    :color="alert.color"
    elevation="5"
    style="z-index: 1001;"
    top
    centered
  >
    <div class="d-flex justify-space-between">
      <div class="d-flex items-center align-center self-end">
        <v-icon class="mr-3">{{ alert.icon }}</v-icon>
        <h4 class="text-center white--text">
          <div>{{ alert.text }}</div>
        </h4>
      </div>
      <v-btn @click="show = false" small dark icon class="ml-10">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Alert",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters(["alert"]),
  },
  watch: {
    alert(value) {
      this.show = value.show;
    },
  },
};
</script>
