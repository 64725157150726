import axios from "axios";

const state = {
  admins: [],
};

const getters = {
  admins: (state) => state.admins,
};

const actions = {
  async fetchAdmins({ commit }, queryParams) {
    const res = await axios.get("/api/admins", { params: queryParams });
    commit("setAdmins", res.data.admins);
  },
};

const mutations = {
  setAdmins: (state, adminsData) => (state.admins = adminsData),
  pushAdmin: (state, admin) => state.admins.push(admin),
  updateAdmin: (state, admin) => {
    const index = state.admins.findIndex((d) => d.id === admin.id);
    Object.assign(state.admins[index], admin);
  },
  removeAdmin: (state, admin) => {
    state.admins = state.admins.filter((a) => {
      return a.id !== admin.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
