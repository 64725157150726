import axios from "axios";

const state = {
  contacts: [],
};

const getters = {
  contacts: (state) => state.contacts,
};

const actions = {
  async fetchContacts({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/contacts`, { params });
      commit("setContacts", data.items);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setContacts: (state, contacts) => (state.contacts = contacts),
  pushContact: (state, contact) => state.contacts.push(contact),
  updateContact: (state, contact) => {
    const index = state.contacts.findIndex((p) => p.id === contact.id);
    if (index !== -1) Object.assign(state.contacts[index], contact);
  },
  removeContact: (state, contact) => {
    state.contacts = state.contacts.filter((d) => {
      return d.id !== contact.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
