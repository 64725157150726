<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deletePatient"
    />

    <v-dialog
      width="1200"
      v-model="showPatientForm"
      @keydown.esc="showPatientForm = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon
            @click="
              showPatientForm = false;
              choosePatient = true;
            "
            style="cursor: pointer"
          >
            mdi-close
          </v-icon>
        </div>
        <PatientForm
          :patient="patient"
          :title="formTitle"
          @patient-updated="handlePatientUpdated"
          @patient-created="handlePatientCreated"
          @on-cancel="showPatientForm = false"
        />
      </v-card>
    </v-dialog>

    <div class="d-md-flex align-center">
      <div class="mr-5">
        <SearchInput @search="handleSearchInput" />
      </div>
      <div class="mb-6">
        <v-btn
          title="Crear un paciente nuevo"
          color="primary"
          small
          depressed
          @click="handleClickNew"
        >
          nuevo
        </v-btn>
      </div>
    </div>

    <p class="caption pasive--text text-right">{{ totalPatients }} pacientes</p>

    <v-data-table
      :headers="headers ? headers : defaultHeaders"
      :items="patients"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="loading"
      loading-text="Cargando..."
      :no-results-text="'Cargando...'"
      :no-data-text="
        filter === ''
          ? 'No hay pacientes registrados'
          : 'Paciente no encontrado'
      "
      item-key="id"
      item-class="text-center"
      :class="clickable ? 'row-pointer' : ''"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="totalPatients"
      @click:row="clickPatient"
    >
      <template slot="item.gender" scope="props">
        <v-chip
          v-if="props.item.gender"
          :color="props.item.gender === 'F' ? 'pink' : 'primary'"
          title="Sexo biológico"
          small
          dark
        >
          {{ props.item.gender === "F" ? "F" : "M" }}
        </v-chip>
      </template>
      <template slot="item.identificationDocument" scope="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ props.item.identificationDocument }}
            </div>
          </template>
          <span class="caption">
            {{ props.item.identificationType }}
          </span>
        </v-tooltip>
      </template>
      <template slot="item.name" scope="props">
        <Patient :patient="props.item" v-if="showPatientDialog">
          {{ props.item.firstName }} {{ props.item.lastName }}
        </Patient>
        <span v-else>
          {{ props.item.firstName }} {{ props.item.lastName }}
        </span>
      </template>
      <template slot="item.lastName" scope="props">
        <Patient :patient="props.item" v-if="showPatientDialog">
          {{ props.item.lastName }}
        </Patient>
        <span v-else>
          {{ props.item.lastName }}
        </span>
      </template>
      <template slot="item.bornDate" scope="props">
        <div v-if="props.item.bornDate">
          {{ getAge(props.item.bornDate) }}
        </div>
        <div v-else>-</div>
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center">
          <v-menu bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                <v-icon size="20px">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="clickPatient(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-human-handsdown</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">Ver perfil</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleClickEdit(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-square-edit-outline</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">Modificar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showWarning(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                    <div class="subtitle-2 font-weight-regular">Eliminar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Patient from "@/components/Patient/Patient";
import PatientForm from "./PatientForm.vue";
import WarningDialog from "../WarningDialog.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { calculateAge } from "@/helpers/common.js";
import axios from "axios";
import SearchInput from "../SearchInput.vue";

export default {
  name: "PatientList",
  props: ["clickable", "headers", "showPatientDialog", "height"],
  components: {
    Patient,
    PatientForm,
    WarningDialog,
    SearchInput,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      defaultHeaders: [
        {
          text: "Identificación",
          value: "identificationDocument",
        },
        { text: "Nombre", value: "name" },
        { text: "Edad", value: "bornDate" },
        { text: "Sexo biológico", value: "gender", align: "center" },
        { text: "Teléfono", value: "phone" },
        { text: "", value: "action", align: "center" },
      ],
      filter: "",
      loading: false,
      deleting: false,
      showPatientForm: false,
      formTitle: "Crear",
      patient: null,
      options: {},
      totalPatients: 0,
    };
  },
  computed: {
    ...mapGetters(["patients", "currentUser", "doctorForms"]),
  },
  methods: {
    ...mapActions(["fetchPatients"]),
    ...mapMutations(["setAlert", "removePatient"]),
    clickPatient(patient) {
      const patientClicked = patient;
      if (this.clickable) this.$emit("patient-click", patientClicked);
    },
    getAge(date) {
      return calculateAge(date);
    },
    handlePatientCreated(patient) {
      this.showPatientForm = false;
      this.$emit("patient-created", patient);
    },
    handlePatientUpdated() {
      this.showPatientForm = false;
    },
    handleClickEdit(item) {
      this.patient = item;
      this.formTitle = "Actualizar";
      this.showPatientForm = true;
    },
    handleClickNew() {
      this.patient = {
        firstName: "",
        lastName: "",
        phone: "",
        bornDate: "",
        gender: "",
        emergencyNumber: "",
        address: "",
        province: "",
        profession: "",
        email: "",
        identificationDocument: "",
        identificationType: "",
        NSS: "",
        ARS: null,
        bloodType: "",
      };
      this.formTitle = "Crear";
      this.showPatientForm = true;
    },
    showWarning(item) {
      this.patient = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar al paciente ${this.patient.firstName} ${this.patient.lastName}?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deletePatient() {
      this.deleting = true;
      try {
        const res = await axios.delete(`/api/patient/${this.patient.id}`);
        this.warning = false;
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.removePatient(this.patient);
        this.$emit("patient-deleted");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
    handleSearchInput(term) {
      this.getData({ term });
    },
    async getData(params) {
      this.loading = true;

      const page = params?.page || 1;
      const pageSize = params?.pageSize || 10;

      let orderBy = [
        {
          column: "patient.firstName",
          direction: "ASC",
        },
      ];

      if (!params?.term) {
        orderBy.unshift({
          column: "patient.updatedAt",
          direction: "DESC",
        });
      }

      const data = await this.fetchPatients({
        term: params?.term,
        page,
        pageSize,
        orderBy,
      });

      this.totalPatients = data?.count || 0;
      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler(value) {
        this.getData({
          page: value.page || 1,
          pageSize: value.itemsPerPage || 15,
        });
      },
      deep: true,
    },
  },
};
</script>
