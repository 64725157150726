<template>
  <div>
    <v-dialog
      v-model="choosePatient"
      width="1200"
      persistent
      @keydown.esc="choosePatient = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-btn text icon @click="choosePatient = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <Title title="Seleccione el paciente" class="mb-8" />
        <PatientsList
          class="my-5"
          @patient-click="chosenPatient"
          @patient-created="chosenPatient"
          :clickable="true"
          :headers="[
            {
              text: 'Identificación',
              value: 'identificationDocument',
            },
            { text: 'Nombre', value: 'name' },
            { text: 'Edad', value: 'bornDate' },
            { text: 'Sexo biológico', value: 'gender', align: 'center' },
            { text: 'Teléfono', value: 'phone' },
          ]"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="appointmentDialog"
      width="900"
      v-if="form.patient"
      persistent
      @keydown.esc="appointmentDialog = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="handleClickClose" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <v-form
          v-model="validation"
          ref="form"
          @submit.prevent="handleSubmit()"
        >
          <v-container class="mb-5">
            <Title :title="title" class="mb-8" />
            <v-row>
              <v-col cols="12" md="6" class="d-flex">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Fecha"
                      readonly
                      outlined
                      :disabled="!showDateInput"
                      :value="formattedDate"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    locale="es-ES"
                    color="primary"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" class="d-flex">
                <v-select
                  v-model="form.location"
                  :items="locations"
                  label="Sede"
                  item-value="id"
                  item-text="name"
                  :disabled="!showLocationInput"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="d-flex">
                <v-text-field
                  :value="form.patient.firstName + ' ' + form.patient.lastName"
                  @click:append="handleClickPatientAppend"
                  type="text"
                  label="Paciente"
                  :append-icon="patientFixed ? 'mdi-lock' : 'mdi-backspace'"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="time"
                  label="Hora estimada"
                  type="time"
                  outlined
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="form.reason"
                  :items="[
                    'Consulta',
                    'Procedimiento',
                    'Entrega de resultado o analítica',
                    'Vacunación',
                    'Chequeo rutinario',
                  ]"
                  label="Motivo"
                  :rules="rules.default"
                  outlined
                ></v-combobox>
              </v-col>
            </v-row>
          </v-container>
          <v-btn
            small
            depressed
            color="primary"
            class="pa-5"
            type="submit"
            :disabled="!validation"
            :loading="loading"
          >
            {{ action === "create" ? "Agregar" : "Actualizar" }}
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import PatientsList from "@/components/Patient/PatientsList";
import PatientForm from "@/components/Patient/PatientForm";
import moment from "moment";
import Title from "../Title.vue";

export default {
  name: "AppointmentForm",
  components: {
    Title,
    PatientsList,
    PatientForm,
  },
  props: ["removeWhenDateChange", "patientFixed", "pickDate"],
  data() {
    return {
      form: {
        patient: null,
        reason: "",
        date: "",
        location: "",
        estimatedTime: "Sin definir",
      },
      action: "create", // create || update
      choosePatient: false,
      newPatient: false,
      appointmentDialog: false,
      time: null,
      menu: false, // time menu
      dateMenu: false, // date menu
      appointmentMode: "Orden de llegada",
      appointmentModes: [
        { text: "Orden de llegada", value: "Orden de llegada" },
        { text: "Hora definida", value: "Hora estimada" },
      ],
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
        select: [(v) => !!v || "Campo obligatorio"],
      },
      loading: false,
      validation: true,
      currentDate: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "attending",
      "appointments",
      "todayAppointments",
      "doctorForms",
      "locations",
      "currentLocation",
    ]),
    title() {
      return this.action === "create" ? "Agregar" : "Actualizar";
    },
    formattedDate() {
      return this.form.date
        ? moment(this.form.date).format("DD/MM/YYYY")
        : moment().format("DD/MM/YYYY");
    },
    formattedTime() {
      if (this.time) return moment(this.time, "HH:mm").format("hh:mm A");
      else return "";
    },
    showDateInput() {
      if (this.pickDate) {
        return true;
      }

      if (this.attending) {
        return this.form.id !== this.attending.id;
      }

      return true;
    },
    showLocationInput() {
      if (this.pickDate) {
        return true;
      }

      return this.action !== "create";
    },
  },
  methods: {
    ...mapMutations(["setAlert", "updateAppointment", "removeAppointment"]),
    handleClickClose() {
      if (this.action === "update" || this.patientFixed) {
        this.appointmentDialog = false;
      } else if (this.action === "create") {
        this.appointmentDialog = false;

        // show choose patient list
        this.choosePatient = true;
      }
    },
    handleClickPatientAppend() {
      if (this.patientFixed) {
        return false;
      }

      this.appointmentDialog = false;
      this.choosePatient = true;
    },
    async handleSubmit() {
      switch (this.action) {
        case "create":
          this.createAppointment();
          break;
        case "update":
          this.editAppointment();
          break;
        default:
          console.log("no submit action...");
          break;
      }
    },
    async createAppointment() {
      try {
        this.loading = true;
        this.form.estimatedTime = this.formattedTime;
        this.form.doctor = this.currentUser.doctor.id;
        const res = await axios.post("/api/appointment", this.form);
        const { appointment, message } = res.data;
        this.$emit("created", appointment);
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.form.reason = "";
        this.$refs.form.resetValidation();
        this.appointmentDialog = false;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
        this.loading = false;
      }
    },
    async editAppointment() {
      try {
        this.loading = true;
        this.form.doctor = this.currentUser.doctor.id;
        this.form.estimatedTime = this.formattedTime;

        const res = await axios.put(
          `/api/appointment/${this.form.id}`,
          this.form
        );
        const { appointment, message } = res.data;

        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        this.$emit("appointment-updated", {
          ...appointment,
          date: moment(appointment.date).format("YYYY-MM-DD"),
        });

        const dateChanged = !(this.currentDate === this.form.date);
        const locationChanged =
          this.currentLocation.id !== this.form.location.id;

        if (locationChanged) {
          this.$emit("location-updated", appointment);
        }

        if (dateChanged) {
          this.$emit("date-updated", appointment);
        } else {
          this.updateAppointment({
            ...appointment,
            date: moment(appointment.date).format("YYYY-MM-DD"),
          });
        }

        this.$refs.form.resetValidation();
        this.appointmentDialog = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    chosenPatient(patient) {
      this.form.patient = patient;
      this.newPatient = false;
      this.choosePatient = false;
      this.appointmentDialog = true;
      this.form.location = this.currentLocation.id;
    },
  },
};
</script>
