import axios from "axios";

const state = {
  expenses: [],
};

const getters = {
  expenses: (state) => state.expenses,
};

const actions = {
  async fetchExpenses({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/expenses`, { params });
      commit("setExpenses", data.items);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setExpenses: (state, expenses) => (state.expenses = expenses),
  pushExpense: (state, expense) => state.expenses.push(expense),
  updateExpense: (state, expense) => {
    const index = state.expenses.findIndex((p) => p.id === expense.id);
    if (index !== -1) Object.assign(state.expenses[index], expense);
  },
  removeExpense: (state, expense) => {
    state.expenses = state.expenses.filter((d) => {
      return d.id !== expense.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
