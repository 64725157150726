import axios from "axios";

const state = {
  invoices: [],
  invoicesCount: 0,
  invoicesQueryParams: null,
};

const getters = {
  invoices: (state) => state.invoices,
  invoicesCount: (state) => state.invoicesCount,
  invoicesQueryParams: (state) => state.invoicesQueryParams,
};

const actions = {
  async fetchInvoices({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/invoices/`, {
        params,
      });
      commit("setInvoicesQueryParams", params);
      commit("setInvoices", data?.items || []);
      commit("setInvoicesCount", data?.count || 0);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  refreshInvoices({ dispatch, getters }) {
    dispatch("fetchInvoices", getters.invoicesQueryParams);
  },
};

const mutations = {
  setInvoices: (state, invoices) => (state.invoices = invoices),
  setInvoicesCount: (state, data) => (state.invoicesCount = data),
  setInvoicesQueryParams: (state, data) => (state.invoicesQueryParams = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
