import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueVirtualScroller from "vue-virtual-scroller";
import es from "javascript-time-ago/locale/es";
import TimeAgo from "javascript-time-ago";
import "./plugins/vuetify-money.js";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;

Vue.component(VueCountdown.name, VueCountdown);
Vue.component("apexchart", VueApexCharts);

Vue.use(VueApexCharts);
Vue.use(VueVirtualScroller);

TimeAgo.addDefaultLocale(es);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
