<template>
  <v-app id="app">
    <ToolBar v-if="loggedIn && !routeWithoutToolbar" />
    <div :class="loggedIn ? 'app-container' : ''">
      <v-main>
        <Alert />
        <LoadingOverlay />
        <router-view></router-view>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Alert from "@/components/Alert";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import ToolBar from "./components/ToolBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Alert,
    LoadingOverlay,
    ToolBar,
  },
  computed: {
    ...mapGetters(["currentUser", "routesWithoutToolbar", "loadingOverlay"]),
    routeWithoutToolbar() {
      return this.routesWithoutToolbar.includes(this.$route.name);
    },
    loggedIn() {
      return this.currentUser;
    },
  },
  created() {
    // set color theme
    const darkTheme = localStorage.getItem("xumed-dark-theme");
    this.$vuetify.theme.dark = darkTheme === "true";
  },
};
</script>

<style>
@media print {
  body {
    overflow: auto !important;
    height: auto !important;
  }
}

body {
  font-family: Arial, sans-serif;
}

.pointer {
  cursor: pointer;
}

.round {
  border-radius: 8px;
}

.v-application {
  margin: 0;
  padding: 0;
}

.v-application--wrap {
  margin: 0;
  padding: 0;
}

.v-app-bar {
  background: rgba(255, 255, 255, 0) !important;
  margin: 0;
  padding: 0;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

#app {
  padding: 0;
  margin: 0;
}

.app-container {
  height: 100%;
  padding: auto;
}

.v-dialog {
  width: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.v-data-table__wrapper > table > tbody > tr:hover {
  background-color: transparent !important;
}

.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
  background-color: #cac9c941 !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-slide-group__next, .v-slide-group__prev {
  min-width: 0px !important; 
}
</style>
