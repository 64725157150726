export const formatFloat = (amount) =>
  parseFloat(parseFloat(amount || 0).toFixed(2));

export const formatInvoiceId = (id) => {
  return id.toString().padStart(8, "0");
};

export const formatCurrency = (amount, locale = "es-DO", currency = "DOP") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(formatFloat(amount))
    .replace(/(\D)\s?(\d)/, "$1 $2");
};
