var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WarningDialog',{ref:"WarningDialogRef",attrs:{"loading":_vm.deleting},on:{"action":_vm.deleteProduct}}),_c('v-dialog',{attrs:{"width":"1200","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showProductForm = false}},model:{value:(_vm.showProductForm),callback:function ($$v) {_vm.showProductForm=$$v},expression:"showProductForm"}},[_c('v-card',{staticClass:"pa-sm-10 pa-5"},[_c('div',{staticClass:"text-right"},[_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showProductForm = false;
            _vm.chooseProduct = true;}}},[_vm._v(" mdi-close ")])],1),(_vm.showProductForm)?_c('ProductForm',{attrs:{"product":_vm.product,"title":_vm.formTitle},on:{"product-updated":_vm.handleProductUpdated,"product-created":_vm.handleProductCreated,"on-cancel":function($event){_vm.showProductForm = false}}}):_vm._e()],1)],1),_c('div',{staticClass:"d-md-flex align-center"},[_c('div',{staticClass:"mr-5"},[_c('SearchInput',{on:{"search":_vm.handleSearchInput}})],1),_c('div',{staticClass:"mb-6"},[_c('v-btn',{attrs:{"title":"Crear nuevo","color":"primary","small":"","depressed":""},on:{"click":_vm.handleClickNew}},[_vm._v(" nuevo ")])],1)]),_c('p',{staticClass:"caption pasive--text text-right"},[_vm._v(" "+_vm._s(_vm.totalProducts)+" "+_vm._s(_vm.totalProducts == 1 ? "resultado" : "resultados")+" ")]),_c('v-data-table',{key:"tableKey",class:_vm.clickable ? 'row-pointer' : '',attrs:{"headers":_vm.listHeaders,"items":_vm.products,"loading":_vm.loading,"loading-text":"Cargando...","no-results-text":'Sin datos...',"no-data-text":_vm.filter === ''
        ? 'No hay productos o servicios registrados'
        : 'Producto o servicio no encontrado',"item-key":"id","item-class":"text-center","disable-sort":"","footer-props":{
      itemsPerPageText: 'Resultados por pág.',
      itemsPerPageAllText: 'Todos',
      pageText: '',
      showCurrentPage: true,
      itemsPerPageOptions: [15, 30, 60],
    },"options":_vm.options,"server-items-length":_vm.totalProducts},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.price",fn:function(props){return [_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatMoney(props.item, Number(props.item.price)))+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(props.item.currency)+" ")])])]}},{key:"item.cost",fn:function(props){return [_c('div',{staticClass:"my-3"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatMoney(props.item, Number(props.item.cost)))+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(props.item.currency)+" ")])])]}},{key:"item.discount",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.discount)+"%")])]}},{key:"item.type",fn:function(props){return [(props.item.type == 'product')?_c('span',[_c('v-chip',{attrs:{"small":""}},[_vm._v("Producto")])],1):_vm._e(),(props.item.type == 'service')?_c('span',[_c('v-chip',{attrs:{"small":""}},[_vm._v("Servicio")])],1):_vm._e()]}},{key:"item.stock",fn:function(props){return [(props.item.type == 'service')?_c('span',[_vm._v("-")]):_vm._e(),(props.item.type == 'product')?_c('v-chip',{staticStyle:{"font-size":"13px","font-weight":"500"},attrs:{"color":Number(props.item.stock || 0) <= Number(props.item.stockAlert || 0)
            ? 'error'
            : 'primary',"small":"","dark":""}},[_vm._v(" "+_vm._s(props.item.stock)+" ")]):_vm._e()]}},{key:"item.updatedAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(props.item.updatedAt))+" ")]}},{key:"item.action",fn:function(props){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-menu',{attrs:{"bottom":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"text":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleClickEdit(props.item)}}},[_c('v-list-item-content',{staticClass:"font-weight-medium"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"18px"}},[_vm._v("mdi-square-edit-outline")]),_c('div',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v("Modificar")])],1)])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showWarning(props.item)}}},[_c('v-list-item-content',{staticClass:"font-weight-medium"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"18px"}},[_vm._v(" mdi-delete ")]),_c('div',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v("Eliminar")])],1)])],1)],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }