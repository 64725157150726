import axios from "axios";

const state = {
  specialties: [],
};

const getters = {
  specialties: (state) => state.specialties,
};

const actions = {
  async fetchSpecialties({ commit }, queryParams) {
    try {
      const res = await axios.get("/api/specialties", { params: queryParams });
      commit("setSpecialties", res.data.specialties);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setSpecialties: (state, data) => (state.specialties = data),
  pushSpecialty: (state, specialty) => state.specialties.push(specialty),
  updateSpecialty: (state, specialty) => {
    const index = state.specialties.findIndex((s) => s.id === specialty.id);
    Object.assign(state.specialties[index], specialty);
  },
  removeSpecialty: (state, specialty) => {
    state.specialties = state.specialties.filter((s) => {
      return s.id !== specialty.id;
    });
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
