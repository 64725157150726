import axios from "axios";

const state = {
  users: [],
};

const getters = {
  usersData: (state) => state.users,
};

const actions = {
  async fetchUsers({ commit }) {
    const res = await axios.get("/api/user");
    commit("setUsersData", res.data.users);
  },
  insertUser({ commit }, user) {
    commit("pushUser", user);
  },
  async fetchUserActivity({ commit, rootState }, params) {
    try {
      const { data } = await axios.get(`/api/user/activity`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};

const mutations = {
  setUsersData: (state, usersData) => (state.users = usersData),
  pushUser: (state, user) => state.users.push(user),
  updateUser: (state, user) => {
    const index = state.users.findIndex((u) => u._id === user._id);
    Object.assign(state.users[index], user);
  },
  removeUser: (state, user) => {
    state.users = state.users.filter((u) => {
      return u._id !== user._id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
