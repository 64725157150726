<template>
  <div>
    <div
      @click="handleClick"
      style="cursor: pointer"
      title="Ver información del paciente"
      class="patient-hover"
    >
      <slot> Patient component </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Patient",
  props: ["patient", "clickFunc"],
  methods: {
    handleClick() {
      if (!this.clickFunc) {
        this.$router.push("/paciente/" + this.patient.id);
      } else {
        this.clickFunc();
      }
    },
  },
};
</script>

<style scoped>
.patient-hover:hover {
  text-decoration: underline;
  opacity: 0.8;
  transition: 0.3s;
}
</style>
