import axios from "axios";

const state = {
  pathologies: [
    "Abrasión corneal",
    "Acné",
    "Aftas bucales",
    "Albinismo",
    "Alergia",
    "Alzhéimer",
    "Amenorrea",
    "Anemia",
    "Aneurisma",
    "Anisakiasis",
    "Apendicitis",
    "Apnea del sueño",
    "Arritmia",
    "Arteriosclerosis",
    "Artritis reumatoide",
    "Artrosis",
    "Ascitis",
    "Asma",
    "Astigmatismo",
    "Ataxia",
    "Atrofia vaginal",
    "Balanitis",
    "Bartolinitis",
    "Bocio",
    "Botulismo",
    "Bronquiectasias",
    "Bronquiolitis obliterante",
    "Bronquitis",
    "Brucelosis",
    "Campylobacter",
    "Cáncer",
    "Cáncer de cabeza y cuello",
    "Cáncer de colon",
    "Cáncer de cuello de útero",
    "Cáncer de esófago",
    "Cáncer de estómago",
    "Cáncer de hígado",
    "Cáncer de laringe",
    "Cáncer de mama",
    "Cáncer de ovario",
    "Cáncer de páncreas",
    "Cáncer de pene",
    "Cáncer de piel",
    "Cáncer de próstata",
    "Cáncer de pulmón",
    "Cáncer de riñón",
    "Cáncer de testículo",
    "Cáncer de tiroides",
    "Cáncer de vejiga",
    "Cáncer oral",
    "Cáncer óseo",
    "Candidiasis",
    "Carbunco",
    "Cataratas",
    "Catarro",
    "Celiaquía",
    "Chancroide",
    "Chikungunya",
    "Ciática",
    "Cirrosis",
    "Cistitis",
    "Citomegalovirus",
    "Clamidia",
    "Cólera",
    "Colesteatoma",
    "Cólico nefrítico",
    "Colitis ulcerosa",
    "Colon irritable",
    "Congestión nasal",
    "Conjuntivitis",
    "Contusiones",
    "Corte de digestión",
    "COVID-19",
    "Crisis de pánico",
    "Cuerpos extraños",
    "Daltonismo",
    "Déficit de la hormona del crecimiento",
    "Degeneración macular",
    "Demencia",
    "Demencia con cuerpos de Lewy",
    "Demencia vascular",
    "Dengue",
    "Depresión",
    "Dermatitis atópica",
    "Derrame ocular",
    "Deterioro cognitivo leve",
    "Diabetes",
    "Diabetes gestacional",
    "Diabetes insípida",
    "Difteria",
    "Disfunción eréctil",
    "Dislexia",
    "Dismenorrea",
    "Dispepsia",
    "Distonía",
    "Distrofia muscular de Duchenne",
    "Diverticulosis",
    "Ébola",
    "Edema pulmonar",
    "Elefantiasis",
    "Encefalitis",
    "Endocarditis",
    "Endometriosis",
    "Enfermedad de Addison",
    "Enfermedad de Behçet",
    "Enfermedad de Chagas",
    "Enfermedad de Crohn",
    "Enfermedad de Huntington",
    "Enfermedad de Kawasaki",
    "Enfermedad de La Peyronie",
    "Enfermedad de Lyme",
    "Enfermedad de Paget",
    "Enfermedad de Wilson",
    "Enfermedad inflamatoria pélvica",
    "Enfermedades de Transmisión Sexual",
    "Enfermedades raras",
    "Epilepsia",
    "EPOC",
    "Escleritis",
    "Esclerodermia",
    "Esclerosis lateral amiotrófica",
    "Esclerosis múltiple",
    "Escoliosis",
    "Esófago de Barrett",
    "Espondilitis anquilosante",
    "Esquizofrenia",
    "Esteatosis hepática",
    "Estrabismo",
    "Estreñimiento",
    "Eyaculación precoz",
    "Faringitis",
    "Fascitis plantar",
    "Fenilcetonuria",
    "Fibrilación auricular",
    "Fibromialgia",
    "Fibrosis quística",
    "Fiebre amarilla",
    "Fiebre de Lassa",
    "Fiebre de Oropouche",
    "Fiebre del valle del Rift",
    "Fiebre hemorrágica de Crimea-Congo",
    "Fiebre tifoidea",
    "Fístula anal",
    "Fractura de dedo",
    "Fractura nasal",
    "Galactorrea",
    "Galactosemia",
    "Gastritis",
    "Gastroenteritis",
    "Giardiasis",
    "GlaucomaGolpe de calor",
    "Gonorrea",
    "Gota",
    "Gripe",
    "Hantavirus",
    "Hemocromatosis",
    "Hemofilia",
    "Hemorragia nasal",
    "Hemorroides",
    "Hepatitis",
    "Herida por arma de fuego",
    "Heridas",
    "Hernia de hiato",
    "Hernia discal",
    "Hernia inguinal",
    "Herpes",
    "Herpes zóster",
    "Hidatidosis",
    "Hiperactividad (TDAH)",
    "Hipercolesterolemia",
    "Hipermetropía",
    "Hiperplasia benigna de próstata",
    "Hipertensión arterial",
    "Hipertiroidismo",
    "Hipoacusia",
    "Hipocalcemia",
    "Hipoglucemia",
    "Hipogonadismo",
    "Hipotensión arterial",
    "Hipotermia",
    "Hipotiroidismo",
    "Hirsutismo",
    "Ictus",
    "Impétigo",
    "Incendios",
    "Incontinencia urinaria",
    "Infarto de miocardio",
    "Infecciones urinarias",
    "Insomnio",
    "Insomnio familiar fatal",
    "Insuficiencia cardiaca",
    "Insuficiencia hepática",
    "Insuficiencia mitral",
    "Insuficiencia renal crónica",
    "Intolerancia a la histamina",
    "Intoxicación etílica",
    "Intoxicación por monóxido de carbono",
    "Intoxicaciones",
    "Juanetes",
    "Ladillas",
    "Laringitis",
    "Legionella",
    "Leishmaniasis",
    "Lepra",
    "Leptospirosis",
    "Lesiones maxilares",
    "Leucemia",
    "Lipodistrofia",
    "Lipotimia",
    "Listeriosis",
    "Litiasis biliar",
    "Lumbalgia",
    "Lupus",
    "Luxaciones",
    "Mal agudo de montaña",
    "Malaria",
    "Melanoma",
    "Melasma",
    "Melioidosis",
    "Meningitis",
    "Menopausia",
    "MERS",
    "Mesotelioma",
    "Miastenia gravis",
    "Micoplasma genital",
    "Microcefalia",
    "Mieloma múltiple",
    "Migraña",
    "Miomatosis uterina",
    "Miopía",
    "Molusco contagioso",
    "Mononucleosis",
    "Mordeduras de animales",
    "Narcolepsia",
    "Neumonía",
    "Neumotórax",
    "Neuralgia del trigémino",
    "Neurofibromatosis",
    "Nistagmo",
    "Norovirus",
    "Obesidad",
    "Obesidad infantil",
    "Ojo seco",
    "Oncocercosis",
    "Onicomicosis",
    "Orquitis",
    "Osteomalacia",
    "Osteomielitis",
    "Osteonecrosis",
    "Osteoporosis",
    "Otitis",
    "Palpitaciones cardíacas",
    "Pancreatitis",
    "Paperas",
    "Parálisis cerebral",
    "Párkinson",
    "Patologías benignas de mama",
    "Pericarditis",
    "Peritonitis",
    "Peste",
    "Pian",
    "Picaduras de insectos",
    "Pie de atleta",
    "Pielone fritis",
    "Pitiriasis versicolor",
    "Policitemia vera",
    "Poliomielitis",
    "Pólipos uterinos",
    "Presbiacusia",
    "Presbicia",
    "Priapismo",
    "Prostatitis",
    "Psoriasis",
    "PTI (trombocitopenia inmune primaria)",
    "Quemaduras",
    "Rabia",
    "Raspaduras y laceraciones",
    "Reacciones alérgicas a medicamentos",
    "Reanimación cardiopulmonar",
    "Reflujo gastroesofágico",
    "Resistencia a la insulina",
    "Retinosis pigmentaria",
    "Rinitis",
    "Rizartrosis",
    "Rosácea",
    "Rubéola",
    "Salmonelosis",
    "Sarampión",
    "Sarcoidosis",
    "Sarcoma de tejidos blandos",
    "Sarna",
    "Sensibilidad química múltiple",
    "Sepsis",
    "Shigelosis",
    "SIBO",
    "VIH / SIDA",
    "Sífilis",
    "Síndrome de Angelman",
    "Síndrome de Cushing",
    "Síndrome de Down",
    "Síndrome de fatiga crónica",
    "Síndrome de Guillain-Barré",
    "Síndrome de Klinefelter",
    "Síndrome de Lynch",
    "Síndrome de Marfan",
    "Síndrome de Noonan",
    "Síndrome de Ovario Poliquístico (SOP)",
    "Síndrome de Patau",
    "Síndrome de piernas inquietas",
    "Síndrome de Rett",
    "Síndrome de Reye",
    "Síndrome de Sanfilippo",
    "Síndrome de Sjögren",
    "Síndrome de Tourette",
    "Síndrome de Turner",
    "Síndrome de Williams",
    "Síndrome de X Frágil",
    "Síndrome del túnel carpiano",
    "Síndrome metabólico",
    "Sinusitis",
    "Siringomielia",
    "Sobredosis",
    "Talasemia",
    "Telangiectasias",
    "Tendinitis",
    "Teniasis",
    "Terremotos",
    "Tétanos",
    "Tinnitus",
    "Tortícolis",
    "Tos ferina",
    "Toxicodermias",
    "Toxoplasmosis",
    "Tracoma",
    "Traqueítis",
    "Trastorno bipolar",
    "Trastorno obsesivo compulsivo",
    "Traumatismo craneal",
    "Tricomoniasis",
    "Tripanosomiasis africana",
    "Tromboembolismo pulmonar",
    "Trombosis venosa",
    "Tuberculosis",
    "Tularemia",
    "Tumores cerebrales",
    "Úlcera",
    "Uretritis",
    "Urticaria",
    "Uveítis",
    "Vaginitis",
    "Vaginosis bacteriana",
    "Varicela",
    "Varices",
    "Varicocele",
    "Vértigo",
    "Virus del Nilo Occidental",
    "Virus Mayaro",
    "Virus Nipah",
    "Virus Usutu",
    "Virus Zika",
    "Vitíligo",
    "VPH",
  ],
  professions: [
    "Abogado",
    "Abogada",
    "Artista",
    "Administrador",
    "Administradora",
    "Agrónomo",
    "Agrónoma",
    "Almacenero",
    "Almacenista",
    "Almacenera",
    "Almacenista",
    "Antropólogo",
    "Antropóloga",
    "Arqueólogo",
    "Arqueóloga",
    "Arquitecto",
    "Arquitecta",
    "Asesor",
    "Asesora",
    "Asistente",
    "Asistenta",
    "Astrofísico",
    "Astrofísica",
    "Astrólogo",
    "Astróloga",
    "Astrónomo",
    "Astrónoma",
    "Atleta",
    "Avicultor",
    "Avicultora",
    "Bedel",
    "Bedela",
    "Bibliotecario",
    "Bibliotecaria",
    "Biofísico",
    "Biofísica",
    "Biógrafo",
    "Biógrafa",
    "Biólogo",
    "Bióloga",
    "Bioquímico",
    "Bioquímica",
    "Botánico",
    "Botánica",
    "Cartógrafo",
    "Cartógrafa",
    "Citólogo",
    "Citóloga",
    "Climatólogo",
    "Climatóloga",
    "Conserje",
    "Conserje",
    "Decano",
    "Decana",
    "Decorador",
    "Decoradora",
    "Defensor",
    "Defensora",
    "Delegado",
    "Delegada",
    "Demógrafo",
    "Demógrafa",
    "Dentista",
    "Dermatólogo",
    "Dermatóloga",
    "Dibujante",
    "Director",
    "Directore",
    "Dirigente",
    "Doctor",
    "Doctora",
    "Ecólogo",
    "Ecóloga",
    "Economista",
    "Educador",
    "Educadora",
    "Enfermero",
    "Enfermera",
    "Farmacéutico",
    "Farmacéutica",
    "Farmacólogo",
    "Farmacóloga",
    "Filósofo",
    "Filósofa",
    "Fiscal",
    "Físico",
    "Física",
    "Fisiólogo",
    "Fisióloga",
    "Fisioterapeuta",
    "Fisioterapeuta",
    "Fotógrafo",
    "Fotógrafa",
    "Funcionario",
    "Funcionaria",
    "Gerente",
    "Informático",
    "Informática",
    "Ingeniero/a civil",
    "Ingeniero/a computacional",
    "Ingeniero/a industrial",
    "Inspector",
    "Inspectora",
    "Jardinero",
    "Jardinera",
    "Jefe",
    "Jefa/jefe",
    "Juez",
    "Jueza/juez",
    "Maestro",
    "Maestra",
    "Mecánico",
    "Medico",
    "Medica",
    "Musicólogo",
    "Musicóloga",
    "Oficial",
    "Oficial/Oficiala",
    "Oficinista",
    "Oficinista",
    "Periodista",
    "Periodista",
    "Portero",
    "Portera",
    "Profesor",
    "Profesora",
    "Programador",
    "Programadora",
    "Psicólogo",
    "Psicóloga",
    "Psicoterapeuta",
    "Psicoterapeuta",
    "Psiquiatra",
    "Psiquiatra",
    "Publicista",
    "Publicista",
    "Publicitario",
    "Publicitaria",
    "Químico Química",
    "Quiropráctico",
    "Quiropráctica",
    "Secretario",
    "Secretaria",
    "Veterinario",
    "Veterinaria",
  ],
  activities: [
    { text: "Caminata", value: "Caminata", icon: "mdi-walk" },
    { text: "Correr", value: "Correr", icon: "mdi-run-fast" },
    {
      text: "Entrenamiento de fuerza",
      value: "Entrenamiento de fuerza",
      icon: "mdi-weight-lifter",
    },
    { text: "Gimnasio", value: "Gimnasio", icon: "mdi-dumbbell" },
    { text: "Atletismo", value: "Atletismo", icon: "mdi-yoga" },
    { text: "Ciclismo", value: "Ciclismo", icon: "mdi-bike" },
    { text: "Tenis", value: "Tenis", icon: "mdi-tennis" },
    {
      text: "Baloncesto / Basketball",
      value: "Baloncesto / Basketball",
      icon: "mdi-basketball-hoop-outline",
    },
    {
      text: "Béisbol / Baseball",
      value: "Béisbol / Baseball",
      icon: "mdi-baseball-bat",
    },
    {
      text: "Fútbol / Football",
      value: "Fútbol / Football",
      icon: "mdi-soccer",
    },
    {
      text: "Sóftbol / Softball",
      value: "Sóftbol / Softball",
      icon: "mdi-baseball-diamond-outline",
    },
    {
      text: "Voleibol / Volleyball",
      value: "Voleibol / Volleyball",
      icon: "mdi-volleyball",
    },
    { text: "Natación", value: "Natación", icon: "mdi-swim" },
    { text: "Senderismo", value: "Senderismo", icon: "mdi-hiking" },
    { text: "Yoga", value: "Yoga", icon: "mdi-meditation" },
    { text: "Baile", value: "Baile", icon: "mdi-dance-ballroom" },
    {
      text: "Deporte de lucha",
      value: "Deporte de lucha",
      icon: "mdi-kabaddi",
    },
    {
      text: "Artes marciales mixtas",
      value: "Artes marciales mixtas",
      icon: "mdi-mixed-martial-arts",
    },
    {
      text: "Skate / patinaje",
      value: "Skate / patinaje",
      icon: "mdi-skateboarding",
    },
    { text: "Arco y flecha", value: "Arco y flecha", icon: "mdi-bow-arrow" },
    { text: "Golf", value: "Golf", icon: "mdi-golf-tee" },
    {
      text: "Football américano",
      value: "Football américano",
      icon: "mdi-football",
    },
    {
      text: "Ping-pong / tenis de mesa",
      value: "Ping-pong / tenis de mesa",
      icon: "mdi-table-tennis",
    },
  ],
  drugs: [
    "Acido fusídico",
    "Acido fólico",
    "Acetaminofén",
    "Allopurino",
    "Albendazol",
    "Ampicilina",
    "Amoxicilina",
    "Amlodipina",
    "Atorvastatina",
    "Azitromicina",
    "Aciclovir",
    "Atenolol",
    "Beclometasona dipropionato",
    "Betametasona (valerato)",
    "Bencilpenicilina sódica",
    "Bencilpenicilina benzatínica",
    "Bupivacaína clorhidrato",
    "Calcio",
    "Carbamazepina",
    "Carvedilol",
    "Captopril",
    "Codeína fosfato",
    "Ceftriaxona",
    "Ciprofloxacina",
    "Cloruro de potasio",
    "Cloruro de sodio",
    "Clonazepam",
    "Clotrimazol",
    "Clopidogrel",
    "Clorhexidina gluconato",
    "Clindamicina",
    "Gentamicina sulfato",
    "Glimepirida",
    "Cefalexina",
    "Difenhidramina HCI",
    "Dimenhidrinato",
    "Diclofenac potasio",
    "Diazepam",
    "Dexametasona",
    "Doxiciclina",
    "Etomidato",
    "Enalapril (maleato)",
    "Enalaprilato (anhidro)",
    "Espironolactona",
    "Ergonovina maleato",
    "Fenilefrina",
    "Fluconazol",
    "Furosemida",
    "Hidroclorotiazida",
    "Hidrocortisona (acetato)",
    "Ibuprofeno",
    "Ivermectina",
    "Inmunoglobulina humana normal",
    "Inmunoglobulina anti D (RH +)",
    "Metformina clorhidrato",
    "Morfina clorhidrato",
    "Metronidazol",
    "Naproxeno",
    "Nifedipina",
    "Nitrofurazona",
    "Paracetamol",
    "Permetrina",
    "Propanolol",
    "Levonorgestrel",
    "Levofloxacina",
    "Losartan (potásico)",
    "Loratadina",
    "Lisinopril",
    "Lidocaína",
    "Lidocaina clorhidrato",
    "Levotiroxina sódica",
    "Prednisona",
    "Salbutamol",
    "Simvastatina",
    "Sulfato ferroso",
    "Sulfato de magnesio",
    "Sulfadiazina",
    "Sulfadiazina de plata",
    "Oxitocina",
    "Retinol (Vitamina A)",
    "Risperidona",
    "Valsartán",
    "Ketoconazol",
    "Talidomida",
    "Tinidazo",
    "Tocoferol (Vitamina E)",
    "Trimetoprima",
    "Vitamina B1 B6 B12",
    "Vitamina C",
    "Warfarina",
  ],
  provinces: [
    "Azúa",
    "Baoruco",
    "Barahona",
    "Dajabón",
    "Distrito Nacional",
    "Duarte",
    "Elías Pina",
    "El Seibo",
    "Espaillat",
    "Hato Mayor",
    "Independencia",
    "La Altagracia",
    "La Romana",
    "La Vega",
    "Maria Trinidad Sanchez",
    "Monseñor Nouel",
    "Monte Cristi",
    "Monte Plata",
    "Pedernales",
    "Peravia",
    "Puerto Plata",
    "Salcedo",
    "Samaná",
    "Sánchez Ramírez",
    "San Cristobal",
    "San Jose de Ocoa",
    "San Juan",
    "San Pedro de Macorís",
    "Santiago",
    "Santiago Rodríguez",
    "Santo Domingo",
    "Valverde",
  ],
  habits: [
    { text: "Café", value: "Café", icon: "mdi-coffee" },
    { text: "Alcohol", value: "Alcohol", icon: "mdi-liquor" },
    { text: "Cigarrillo", value: "Cigarrillo", icon: "mdi-smoking" },
    { text: "Hookah", value: "Hookah", icon: "mdi-smoke" },
    { text: "Vape", value: "Vape", icon: "mdi-water-alert" },
    { text: "Tabaco", value: "Tabaco", icon: "mdi-cigar" },
  ],
  availableCurrencies: [
    { text: "Peso dominicano", value: "DOP", locale: "es-DO", prefix: 'RD$' },
    { text: "Dólar estadounidense", value: "USD", locale: "en-US", prefix: 'USD' },
    { text: "Euro", value: "EUR", locale: "es-ES", prefix: 'EUR' },
  ],
  routesWithoutToolbar: ["History"],
  countries: [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "España",
    "Estados Unidos",
    "Guatemala",
    "Honduras",
    "Jamaica",
    "México",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
    "Venezuela",
  ],
  configuration: null,
  loadingConfiguration: null,
};

const getters = {
  pathologies: (state) => state.pathologies,
  activities: (state) => state.activities,
  professions: (state) => state.professions,
  drugs: (state) => state.drugs,
  provinces: (state) => state.provinces,
  habits: (state) => state.habits,
  countries: (state) => state.countries,
  availableCurrencies: (state) => state.availableCurrencies,
  routesWithoutToolbar: (state) => state.routesWithoutToolbar,
  configuration: (state) => state.configuration,
  loadingConfiguration: (state) => state.loadingConfiguration,
};

const actions = {
  async getConfiguration({ commit }, params) {
    try {
      commit("setLoadingConfiguration", true);
      const { data } = await axios.get(`/api/configuration`, { params });
      commit("setConfiguration", data.item);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      commit("setLoadingConfiguration", false);
    }
  },
};

const mutations = {
  setConfiguration: (state, data) => (state.configuration = data),
  setLoadingConfiguration: (state, data) => (state.loadingConfiguration = data),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
