<template>
  <div>
    <v-form @submit.prevent="handleSubmit" ref="form">
      <Title :title="title" class="mb-8" />
      <div>
        <v-row>
          <v-col cols="12" md="4">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha"
                  readonly
                  outlined
                  :value="formattedDate"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                locale="es-ES"
                color="primary"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="locationId"
              :items="locations"
              @change="fetchAppointments"
              label="Sede *"
              item-value="id"
              :item-text="(value) => value.name"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="appointmentId"
              :items="patientAppointments"
              label="Consulta"
              item-value="id"
              :item-text="
                (value) => formatDate(value.date) + ' - ' + value.reason
              "
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="currency"
              :items="currenciesOptions"
              label="Moneda"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="comment"
              label="Observaciones y/o comentarios"
              type="text"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn depressed @click="chooseProduct = true">agregar</v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="items.length"
              disable-sort
              no-data-text="Debes agregar un producto o servicio a la factura..."
              item-class="text-center"
              class="my-5"
              hide-default-footer
            >
              <template slot="item.price" slot-scope="props">
                {{ formatInvoiceCurrency(props.item.price) }}
              </template>
              <template slot="item.descount" slot-scope="props">
                <div>{{ formatFloat(props.item.descountPercent) }}%</div>
              </template>
              <template slot="item.import" slot-scope="props">
                {{ formatInvoiceCurrency(calculateImport(props.item)) }}
              </template>
              <template slot="item.action" scope="props">
                <div class="d-flex justify-center my-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        small
                        icon
                        class="mx-1"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleClickEdit(props.item)"
                      >
                        <v-icon small> mdi-square-edit-outline </v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        small
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        @click="removeFromList(props.item)"
                      >
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Eliminar</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12" class="text-left mb-3">
            <h4 class="d-md-flex pasive--text font-weight-regular">
              <span>Subtotal:</span>
              <span class="ml-1">{{ formatInvoiceCurrency(subtotal) }}</span>
            </h4>
            <h4 class="d-md-flex pasive--text font-weight-regular">
              <span>Descuento:</span>
              <span class="ml-1">{{
                formatInvoiceCurrency(descount.total)
              }}</span>
              <span class="ml-1">({{ descount.percent }}%)</span>
            </h4>
            <h3 class="d-md-flex">
              <span class="font-weight-regular">Total:</span>
              <span class="font-weight-bold ml-1">
                {{ formatInvoiceCurrency(total) }}
              </span>
            </h3>
          </v-col>
        </v-row>
      </div>
      <v-row
        v-if="
          action !== 'update' &&
          (currentUser.doctor.subscription == 'premium' ||
            currentUser.doctor.subscription == 'pro')
        "
        no-gutters
      >
        <v-col cols="12" md="3">
          <v-checkbox
            v-if="!disableSendEmail"
            v-model="sendEmail"
            label="Enviar por email"
            :readonly="disableSendEmail"
          ></v-checkbox>
          <div v-else class="mb-5 caption pasive--text">
            Nota: Registra el email del paciente para compartir esta factura
          </div>
        </v-col>
      </v-row>
      <v-row :no-gutters="action !== 'update'">
        <v-col cols="12">
          <v-btn
            v-if="action !== 'update'"
            depressed
            small
            color="primary"
            class="pa-5 mr-3"
            @click="showPaymentDialog = true"
            :disabled="!activeSaveButton"
            :loading="loading"
          >
            Realizar pago
          </v-btn>
          <v-btn
            depressed
            small
            :outlined="action !== 'update'"
            type="submit"
            color="primary"
            class="pa-5"
            :disabled="!activeSaveButton"
            :loading="loading"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      width="800"
      v-model="showItemDialog"
      @keydown.esc="showItemDialog = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showItemDialog = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title
          :title="itemAction === 'add' ? 'Agregar' : 'Modificar'"
          class="mb-8"
        />
        <v-text-field
          v-if="currentProduct"
          :value="currentProduct.name"
          type="text"
          label="Descripción"
          readonly
          outlined
        ></v-text-field>
        <v-text-field
          v-model="itemQuantity"
          type="number"
          label="Cantidad *"
          outlined
        ></v-text-field>
        <v-text-field
          :value="formatInvoiceCurrency(itemPrice)"
          :readonly="true"
          label="Precio *"
          outlined
        ></v-text-field>

        <v-row no-gutters>
          <v-col md="8" cols="12" class="pr-md-5">
            <v-text-field
              v-if="currentDiscountType == 'percent'"
              v-model="itemDescountPercent"
              type="number"
              label="Descuento"
              append-icon="mdi-percent"
              :hint="!descountInRange ? 'Máximo descuento aplicado...' : ''"
              outlined
            >
            </v-text-field>
            <vuetify-money
              v-if="currentDiscountType == 'money'"
              v-model="moneyDiscount"
              label="Descuento"
              placeholder="0.00"
              :outlined="true"
              :clearable="true"
              :backgroundColor="
                $vuetify.theme.currentTheme.vuetifyMoneyBackground
              "
              :options="{
                locale: 'ja-JP',
                prefix: 'RD$. ',
                suffix: '',
                length: 9,
                precision: 2,
              }"
              :properties="{
                hint: !descountInRange ? 'Máximo descuento aplicado...' : '',
                persistentHint: true,
              }"
            />
          </v-col>
          <v-col md="4" cols="12">
            <v-select
              v-model="currentDiscountType"
              :items="[
                {
                  text: 'Porcentaje',
                  value: 'percent',
                },
                {
                  text: 'Importe',
                  value: 'money',
                },
              ]"
              label="Tipo de descuento"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col>
            <h4 class="d-md-flex secondary--text">
              <span class="font-weight-regular">Importe:</span>
              <span class="font-weight-bold ml-1">
                {{ itemImport }}
              </span>
            </h4>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <v-btn
              depressed
              small
              color="primary"
              class="pa-5"
              type="submit"
              :disabled="disableItemBtn"
              @click="submitItem"
            >
              {{ itemAction === "add" ? "Agregar" : "Modificar" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      width="800"
      v-model="showPaymentDialog"
      @keydown.esc="showPaymentDialog = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showPaymentDialog = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title :title="'Realizar pago'" class="mb-8" />

        <div v-if="currency == 'DOP'">
          <vuetify-money
            v-if="paymentMethod !== 'Mixto'"
            v-model="payment"
            label="Importe a pagar"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
          />
          <v-select
            v-model="paymentMethod"
            :items="[
              {
                text: 'Efectivo',
                value: 'Efectivo',
              },
              {
                text: 'Tarjeta de crédito/débito',
                value: 'Tarjeta de crédito/débito',
              },
              {
                text: 'Transferencia bancaria',
                value: 'Transferencia bancaria',
              },
              {
                text: 'Mixto',
                value: 'Mixto',
              },
            ]"
            label="Método de pago"
            outlined
          ></v-select>
          <vuetify-money
            v-if="paymentMethod == 'Mixto'"
            v-model="cash"
            label="Efectivo"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
            :properties="{
              hint: '',
              appendIcon: 'mdi-cash',
            }"
          />
          <vuetify-money
            v-if="paymentMethod == 'Mixto'"
            v-model="bank"
            label="Transferencia bancaria o cheque"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
            :properties="{
              hint: '',
              appendIcon: 'mdi-bank',
            }"
          />
          <vuetify-money
            v-if="paymentMethod == 'Mixto'"
            v-model="creditCard"
            label="Tarjeta crédito/débito"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
            :properties="{
              hint: '',
              appendIcon: 'mdi-credit-card',
            }"
          />
        </div>

        <div v-else>
          <vuetify-money
            v-if="paymentMethod !== 'Mixto'"
            v-model="foreignPayment"
            label="Importe a pagar"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: currentCurrencyPrefix + ' ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
          />
          <v-select
            v-model="paymentMethod"
            :items="[
              {
                text: 'Efectivo',
                value: 'Efectivo',
              },
              {
                text: 'Tarjeta de crédito/débito',
                value: 'Tarjeta de crédito/débito',
              },
              {
                text: 'Transferencia bancaria',
                value: 'Transferencia bancaria',
              },
              {
                text: 'Mixto',
                value: 'Mixto',
              },
            ]"
            label="Método de pago"
            outlined
          ></v-select>
          <vuetify-money
            v-if="paymentMethod == 'Mixto'"
            v-model="foreignCash"
            label="Efectivo"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: currentCurrencyPrefix + ' ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
            :properties="{
              hint: '',
              appendIcon: 'mdi-cash',
            }"
          />
          <vuetify-money
            v-if="paymentMethod == 'Mixto'"
            v-model="foreignBank"
            label="Transferencia bancaria o cheque"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: currentCurrencyPrefix + ' ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
            :properties="{
              hint: '',
              appendIcon: 'mdi-bank',
            }"
          />
          <vuetify-money
            v-if="paymentMethod == 'Mixto'"
            v-model="foreignCreditCard"
            label="Tarjeta crédito/débito"
            placeholder="0.00"
            :outlined="true"
            :clearable="true"
            :backgroundColor="
              $vuetify.theme.currentTheme.vuetifyMoneyBackground
            "
            :options="{
              locale: 'ja-JP',
              prefix: currentCurrencyPrefix + ' ',
              suffix: '',
              length: 9,
              precision: 2,
            }"
            :properties="{
              hint: '',
              appendIcon: 'mdi-credit-card',
            }"
          />
        </div>

        <v-text-field
          v-model="paymentComments"
          type="text"
          label="Comentarios del pago"
          outlined
        ></v-text-field>
        <h4 class="d-md-flex secondary--text mb-1">
          <span class="font-weight-regular">Total a pagar:</span>
          <span class="font-weight-bold ml-1">
            {{ formatInvoiceCurrency(total) }}
          </span>
        </h4>
        <h4 class="d-md-flex secondary--text mb-1">
          <span class="font-weight-regular">Restante a pagar:</span>
          <span class="font-weight-bold ml-1">
            {{ formatInvoiceCurrency(diff) }}
          </span>
        </h4>
        <h4 v-if="cashChange" class="d-md-flex secondary--text mb-1">
          <span class="font-weight-regular">Cambio:</span>
          <span class="font-weight-bold ml-1 error--text">
            {{ formatInvoiceCurrency(cashChange) }}
          </span>
        </h4>
        <v-btn
          depressed
          small
          color="primary"
          class="pa-5 mt-5"
          @click="handleSubmit"
          :disabled="disableTotalPaidBtn"
          :loading="loading"
        >
          Procesar
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="chooseProduct"
      width="100%"
      height="100%"
      persistent
      @keydown.esc="chooseProduct = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-btn text icon @click="chooseProduct = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <Title
          :title="'Seleccione el producto o servicio a agregar'"
          class="mb-8"
        />
        <ProductList
          :clickable="true"
          @product-click="handleProductClick"
          :headers="[
            { text: 'Tipo', value: 'type' },
            {
              text: 'Nombre',
              value: 'name',
            },
            { text: 'Stock', value: 'stock' },
            { text: 'Precio de venta', value: 'price' },
            { text: '', value: 'action', align: 'center' },
          ]"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { formatCurrency, formatFloat } from "../../helpers/formatters";
import Title from "../Title.vue";
import ProductList from "../Product/ProductList.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "InvoiceForm",
  components: {
    Title,
    ProductList,
  },
  props: ["calculateImport"],
  data() {
    return {
      chooseProduct: false,
      display: 1,
      title: "",
      action: "update",
      id: null,

      cash: "",
      creditCard: "",
      bank: "",

      foreignPayment: "",
      foreignCash: "",
      foreignCreditCard: "",
      foreignBank: "",

      comment: "",
      type: "Contado",
      itemId: "",
      itemPrice: "",
      itemQuantity: 1,
      itemDescountPercent: "",
      items: [],
      itemAction: "add",
      itemLocalId: 0,
      date: "",
      dateMenu: false,
      hiddenInputs: [],
      loading: false,
      showPaymentDialog: false,
      showItemDialog: false,
      patient: null,
      appointmentId: null,
      locationId: null,
      patientAppointments: [],
      currency: "DOP",
      currencyRate: "",
      currentProduct: null,
      appointment: null,
      paymentMethod: "Efectivo",
      payment: "",
      paymentComments: "",
      headers: [
        { text: "Descripción", value: "product.name" },
        { text: "Cantidad", value: "quantity", align: "center" },
        { text: "Precio", value: "price" },
        { text: "Descuento", value: "descount", align: "center" },
        { text: "Importe", value: "import" },
        { text: "", value: "action", align: "center" },
      ],
      stockAdjustment: [],
      sendEmail: false,
      disableSendEmail: false,
      moneyDiscount: "",
      currentDiscountType: "percent",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "locations",
      "currentLocation",
      "availableCurrencies",
      "currencies",
    ]),
    currenciesOptions() {
      const options =
        this.currencies
          ?.filter((c) => c.value !== "DOP")
          .map((c) => {
            const text = this.availableCurrencies.filter(
              (ac) => ac.value == c.code
            )[0].text;
            return {
              text,
              value: c.code,
            };
          }) || [];

      return [
        {
          text: "Peso dominicano",
          value: "DOP",
        },
        ...options,
      ];
    },
    descountInRange() {
      if (this.itemDescountPercent) {
        const discountInput = Number(this.itemDescountPercent || 0);
        const maxProductDiscount = Number(this.currentProduct?.discount || 0);
        return maxProductDiscount
          ? discountInput <= maxProductDiscount
          : discountInput >= 0 && discountInput <= 100;
      } else {
        return true;
      }
    },
    disableItemBtn() {
      return !this.itemQuantity || !this.descountInRange;
    },
    disableTotalPaidBtn() {
      const isPaying = this.totalPaid <= 0;
      const isExceeding =
        this.paymentMethod !== "Efectivo" && this.totalPaid > this.total;
      return isPaying || isExceeding;
    },
    formattedDate() {
      return this.date
        ? moment(this.date).format("DD/MM/YYYY")
        : moment().format("DD/MM/YYYY");
    },
    dateToSave() {
      return this.date
        ? moment(this.date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    },
    totalPaid() {
      let total = 0;

      total += parseFloat(this.cash || 0);
      total += parseFloat(this.creditCard || 0);
      total += parseFloat(this.bank || 0);

      return total;
    },
    diff() {
      const dif = this.total - this.totalPaid;
      return dif < 0 ? 0 : dif;
    },
    cashChange() {
      return this.paymentMethod == "Efectivo" && this.diff == 0
        ? Math.abs(this.total - this.totalPaid)
        : 0;
    },
    simpleTotalPaid() {
      const importPrice = this.calculateImport({
        price: this.totalPaid,
        quantity: 1,
        descountPercent: this.itemDescountPercent,
      });

      return this.formatInvoiceCurrency(importPrice);
    },
    totalPaidFormated() {
      return this.formatInvoiceCurrency(this.totalPaid);
    },
    subtotal() {
      const items = this.items;
      let sum = 0;
      for (let item of items) {
        sum += parseFloat(item.price) * parseFloat(item.quantity);
      }

      return sum;
    },
    total() {
      const items = this.items;
      let sum = 0;
      for (let item of items) {
        sum += this.calculateImport(item);
      }

      return sum;
    },
    descount() {
      const total = this.total;
      const subtotal = this.subtotal;

      const descount = subtotal - total;
      const percent = subtotal ? (descount * 100) / subtotal : 0;

      return {
        percent: percent.toFixed(2),
        total: descount,
      };
    },
    itemImport() {
      if (this.itemPrice && this.itemQuantity) {
        const descount = this.itemDescountPercent
          ? this.itemDescountPercent / 100
          : 0;

        const itemTotal =
          this.itemPrice * this.itemQuantity -
          this.itemPrice * this.itemQuantity * descount;

        return this.formatInvoiceCurrency(itemTotal);
      }

      return "RD$ 0.00";
    },
    activeSaveButton() {
      return this.items.length > 0;
    },
    currentCurrencyRate() {
      return this.currencyRate
        ? Number(this.currencyRate)
        : Number(
            this.currencies.filter((c) => c.code == this.currency)[0]?.rate || 1
          );
    },
    currentCurrencyPrefix() {
      return this.availableCurrencies.filter((c) => c.value == this.currency)[0]
        .prefix;
    },
  },
  methods: {
    ...mapMutations(["updateAppointment", "setAlert", "updateProductStock"]),
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatInvoiceCurrency(n) {
      if (this.currency !== "DOP") {
        n = n / this.currentCurrencyRate;
      }
      const currency = this.availableCurrencies.filter(
        (c) => c.value == this.currency
      )[0];
      return formatCurrency(n, currency.locale, currency.value);
    },
    setInputsValues({ invoice, patient, appointment }) {
      this.id = invoice?.id;
      this.comment = invoice?.comment || "";
      this.bank = invoice?.bank || "";
      this.cash = invoice?.cash || "";
      this.creditCard = invoice?.creditCard || "";

      const defaultCurrency = localStorage.getItem("xumed-currency");
      this.currency = invoice?.currency || defaultCurrency ||"DOP";
      this.currencyRate = invoice?.currencyRate || "";
      this.date = invoice?.date || moment().format("YYYY-MM-DD");
      

      this.items = invoice?.items
        ? invoice?.items?.map((i) => ({ ...i, localId: uuidv4() }))
        : [];

      this.patient = invoice?.patient ? invoice?.patient : patient;

      this.appointmentId = invoice?.appointment?.id
        ? invoice?.appointment?.id
        : appointment?.id;

      this.appointment = invoice?.appointment;

      this.locationId = invoice?.location
        ? invoice.location.id
        : this.currentLocation?.id && this.currentLocation.id !== -1
        ? this.currentLocation?.id
        : this.locations[0].id;

      this.fetchAppointments();
    },
    handleSubmit() {
      if (this.action === "create") {
        this.createInvoice();
      }

      if (this.action === "update") {
        this.updateInvoice();
      }
    },
    openItemToAddDialog() {
      this.itemAction = "add";
      this.showItemDialog = true;
    },
    submitItem() {
      if (this.itemAction === "add") {
        this.addToList();
      }

      if (this.itemAction === "update") {
        this.updateFromToList();
      }
    },
    handleProductClick(product) {
      this.currentProduct = product;
      this.itemPrice = this.currentProduct.price;
      this.itemAction = "add";
      this.showItemDialog = true;
    },
    addToList() {
      this.items.push({
        product: this.currentProduct,
        price: this.itemPrice,
        descountPercent: this.itemDescountPercent || 0,
        quantity: this.itemQuantity,
        localId: uuidv4(),
      });

      if (this.currentProduct.type == "product") {
        this.updateStockFromAdd();
      }

      this.showItemDialog = false;
      this.chooseProduct = false;
      this.itemDescountPercent = "";
      this.itemQuantity = 1;
    },
    formatFloat(n) {
      return formatFloat(n);
    },
    updateStockFromAdd() {
      const index = this.stockAdjustment.findIndex(
        ({ id }) => id === this.currentProduct.id
      );
      if (index !== -1) {
        const productStock = this.stockAdjustment[index];
        productStock.stock = formatFloat(
          Number(productStock.stock) - Number(this.itemQuantity)
        );
        Object.assign(this.stockAdjustment[index], productStock);
      } else {
        this.stockAdjustment.push({
          id: this.currentProduct.id,
          stock: formatFloat(
            Number(this.currentProduct.stock) - Number(this.itemQuantity)
          ),
        });
      }
    },
    updateFromToList() {
      if (this.currentProduct.type == "product") {
        this.updateStockFromUpdate();
      }

      this.items = this.items.map((item) => {
        if (item.localId === this.itemLocalId) {
          return {
            ...item,
            product: this.currentProduct,
            price: this.itemPrice,
            descountPercent: this.itemDescountPercent || 0,
            quantity: this.itemQuantity,
          };
        }

        return item;
      });

      this.showItemDialog = false;

      this.itemPrice = "";
      this.itemDescountPercent = "";
      this.itemQuantity = 1;
    },
    updateStockFromUpdate() {
      const item = this.items.filter(
        (item) => item.localId === this.itemLocalId
      )[0];
      if (item) {
        const diff = Number(item.quantity) - Number(this.itemQuantity);
        const index = this.stockAdjustment.findIndex(
          ({ id }) => id === this.currentProduct.id
        );
        if (index !== -1) {
          this.stockAdjustment[index].stock = formatFloat(
            this.stockAdjustment[index].stock + diff
          );
        } else {
          this.stockAdjustment.push({
            id: this.currentProduct.id,
            stock: formatFloat(Number(this.currentProduct.stock) + diff),
          });
        }
      }
    },
    removeFromList(itemToRemove) {
      this.updateStockFromRemove(itemToRemove);
      this.items = this.items.filter(
        (item) => item.localId !== itemToRemove.localId
      );
    },
    updateStockFromRemove(item) {
      const index = this.stockAdjustment.findIndex(
        ({ id }) => id === item.product.id
      );
      if (index !== -1) {
        this.stockAdjustment[index].stock = formatFloat(
          this.stockAdjustment[index].stock + Number(item.quantity)
        );
      } else {
        this.stockAdjustment.push({
          id: item.product.id,
          stock: formatFloat(
            Number(item.product.stock) + Number(item.quantity)
          ),
        });
      }
    },
    handleClickEdit(item) {
      this.itemAction = "update";
      this.itemId = item?.id;
      this.currentProduct = item.product;
      this.itemPrice = item.price;
      this.itemDescountPercent = item.descountPercent;
      this.itemQuantity = item.quantity;
      this.itemLocalId = item.localId;

      const price = Number(item.price) || 0;
      const percent = (Number(item.descountPercent) || 0) / 100;
      this.moneyDiscount = price * percent;
      this.showItemDialog = true;
    },
    async createInvoice() {
      try {
        this.loading = true;

        const payload = this.getPayload();
        const { data } = await axios.post("/api/invoice", payload);

        if (data.message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: data.message,
          });

          for (let productStock of payload?.stockAdjustment) {
            this.updateProductStock(productStock);
          }
        }

        const invoice = {
          ...data.invoice,
          emailSent: this.sendEmail,
        };

        this.$emit("created", { invoice });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message || "Error inesperado al guardar",
        });
      } finally {
        this.loading = false;
      }
    },
    async updateInvoice() {
      try {
        this.loading = true;

        const payload = this.getPayload();
        payload.id = this.id;
        delete payload.patient;

        const { data } = await axios.put(`/api/invoice/${payload.id}`, payload);

        if (data.message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: data.message,
          });

          for (let productStock of payload?.stockAdjustment) {
            this.updateProductStock(productStock);
          }
        }

        this.$emit("updated", {
          invoice: data.invoice,
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message || "Error inesperado al guardar",
        });
      } finally {
        this.loading = false;
      }
    },
    getPayload() {
      let totalPaid = 0;

      const isPaid = this.cashChange || this.totalPaid >= this.total;
      totalPaid = isPaid ? this.total : this.totalPaid;

      const paymentMethods = {
        cash: parseFloat(this.cash - this.cashChange || 0),
        creditCard: parseFloat(this.creditCard || 0),
        bank: parseFloat(this.bank || 0),
      };

      const { cash, creditCard, bank } = paymentMethods;
      const totalPayment = cash + creditCard + bank;
      const payment = totalPayment
        ? {
            ...paymentMethods,
            date: this.dateToSave,
            total: totalPayment,
            comment: this.paymentComments,
          }
        : null;

      const currencyRate =
        this.currency == "DOP" ? null : this.currentCurrencyRate;

      return {
        totalPaid: totalPaid || 0,
        total: this.total,
        totalDiscount: parseFloat(this.descount.percent),
        comment: this.comment,
        date: this.dateToSave,
        doctorId: this.currentUser.doctor.id,
        appointment: this.appointmentId,
        patient: this.patient.id,
        location: this.locationId,
        items: this.items,
        stockAdjustment: this.stockAdjustment,
        sendEmail:
          this.sendEmail &&
          (this.currentUser?.doctor?.subscription == "premium" ||
            this.currentUser?.doctor?.subscription == "pro"),
        payment,
        currency: this.currency,
        currencyRate,
      };
    },
    resetForm() {
      this.$refs.form?.reset();
    },
    fetchAppointments() {
      axios
        .get(`/api/appointments/`, {
          params: {
            doctorId: this.currentUser.doctor.id,
            patientId: this.patient?.id,
            locationId: this.locationId,
            noInvoices: true,
            orderBy: [
              {
                column: "appointment.date",
                direction: "DESC",
              },
            ],
          },
        })
        .then((res) => {
          this.patientAppointments = res?.data?.items || [];
          if (this.patientAppointments.length == 0 && this.appointment) {
            this.patientAppointments.push(this.appointment);
          }
        })
        .catch((error) => console.log(error));
    },
    setPayment() {
      this.cash = 0;
      this.bank = 0;
      this.creditCard = 0;

      if (this.paymentMethod == "Efectivo") {
        this.cash = this.payment;
      }

      if (this.paymentMethod == "Tarjeta de crédito/débito") {
        this.creditCard = this.payment;
      }

      if (this.paymentMethod == "Transferencia bancaria") {
        this.bank = this.payment;
      }
    },
  },
  watch: {
    currency: {
      handler(val) {
        localStorage.setItem("xumed-currency", val);
      },
    },
    paymentMethod: {
      handler() {
        this.setPayment();
      },
    },
    foreignPayment: {
      handler(val) {
        this.payment = this.formatFloat(Number(val) * this.currentCurrencyRate);
      },
    },
    foreignCash: {
      handler(val) {
        this.cash = this.formatFloat(Number(val) * this.currentCurrencyRate);
      },
    },
    foreignCreditCard: {
      handler(val) {
        this.creditCard = this.formatFloat(
          Number(val) * this.currentCurrencyRate
        );
      },
    },
    foreignBank: {
      handler(val) {
        this.bank = this.formatFloat(Number(val) * this.currentCurrencyRate);
      },
    },
    payment: {
      handler() {
        this.setPayment();
      },
    },
    moneyDiscount: {
      handler(val) {
        const discount = Number(val);
        const itemPrice = this.itemPrice || 1;
        this.itemDescountPercent = this.formatFloat(
          (discount * 100) / itemPrice
        );
      },
      immediate: true,
    },
  },
};
</script>
