<template>
  <div class="d-flex align-center">
    <v-avatar v-if="icon" slot="icon" :color="iconBg" size="35" dark>
      <v-icon :color="iconColor" size="20">{{ icon }}</v-icon>
    </v-avatar>
    <div :class="icon ? 'ml-3' : ''">
      <div
        v-if="label"
        :class="`info-item-label caption  ${
          off
            ? `text-decoration-line-through  ${labelColor}--text`
            : important
            ? `font-weight-bold ${labelColor}--text`
            : `${labelColor}--text`
        }`"
      >
        {{ label }}
      </div>
      <div v-if="!hideVal">
        <span
          v-if="!val && val !== 0 && chipVals.length === 0"
          :class="`info-item-value subtitle-1  light_secondary--text ${
            off ? 'text-decoration-line-through' : ''
          }`"
          >-</span
        >
        <span
          v-else-if="chipVals.length > 0"
          :class="`info-item-value subtitle-1 ${
            off ? 'text-decoration-line-through pasive--text' : ''
          }`"
        >
          <v-chip
            v-for="(chip, index) in chipVals"
            :key="index"
            :color="chip.color"
            class="ml-1"
            small
            dark
            style="font-size: 13px; font-weight: 500"
          >
            {{ chip.text }}
          </v-chip>
        </span>
        <span
          v-else
          :class="`info-item-value subtitle-1  ${
            off
              ? 'text-decoration-line-through pasive--text'
              : important
              ? 'font-weight-bold'
              : ''
          }`"
          >{{ val || "-" }}
        </span>
      </div>
      <slot name="val" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoItem",
  props: {
    label: {
      type: String,
      default: "Registro",
      require: true,
    },
    val: {
      default: "",
      require: false,
    },
    hideVal: {
      type: Boolean,
      default: false,
      require: false,
    },
    chipVals: {
      type: Array,
      default: () => [],
      require: false,
    },
    icon: {
      type: String,
      require: false,
    },
    iconColor: {
      type: String,
      require: false,
      default: "xumed_grey",
    },
    iconBg: {
      type: String,
      require: false,
      default: "xumed_light",
    },
    labelColor: {
      type: String,
      require: false,
      default: "pasive",
    },
    off: {
      type: Boolean,
      require: false,
    },
    important: {
      type: Boolean,
      require: false,
    },
  },
};
</script>
