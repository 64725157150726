<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="loadingWarning"
      @action="handleWarningAction"
    />

    <v-dialog
      v-model="showPaymentForm"
      @keydown.esc="showPaymentForm = false"
      persistent
      width="1400"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showPaymentForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <InvoiceForm
          ref="invoiceFormRef"
          :formatMoney="formatMoney"
          :calculateImport="calculateImport"
          @cancel="showPaymentForm = false"
          @created="handleInvoiceCreated"
          @updated="handleInvoiceUpdated"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showInvoicePaymentForm"
      @keydown.esc="showInvoicePaymentForm = false"
      persistent
      width="900"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon
            @click="showInvoicePaymentForm = false"
            style="cursor: pointer"
          >
            mdi-close
          </v-icon>
        </div>
        <InvoicePaymentForm
          ref="invoicePaymentFormRef"
          :invoice="invoice"
          @cancel="showInvoicePaymentForm = false"
          @created="handleInvoicePaymentCreated"
          @updated="handleInvoicePaymentUpdated"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="choosePatient"
      width="1200"
      persistent
      @keydown.esc="choosePatient = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-btn text icon @click="choosePatient = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <Title :title="'Seleccione el paciente'" class="mb-8" />
        <PatientsList
          class="my-5"
          :clickable="true"
          @patient-click="chosenPatient"
          @patient-created="chosenPatient"
          :headers="[
            {
              text: 'Identificación',
              value: 'identificationDocument',
            },
            { text: 'Nombre', value: 'name' },
            { text: 'Edad', value: 'bornDate' },
            { text: 'Sexo biológico', value: 'gender', align: 'center' },
            { text: 'Teléfono', value: 'phone' },
          ]"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPaymentDetails"
      @keydown.esc="showPaymentDetails = false"
      width="100%"
      persistent
    >
      <v-card v-if="patient && invoice" class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showPaymentDetails = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <h2 class="title secondary--text">
          {{ "Factura " + patient.firstName + " " + patient.lastName }}
        </h2>
        <v-chip small> Cod. {{ formatInvoiceId(invoice.id) }} </v-chip>
        <v-divider class="mt-2"></v-divider>
        <div class="mb-8 d-flex">
          <v-tabs
            v-model="tab"
            icons-and-text
            height="40"
            color="secondary"
            slider-color="secondary"
          >
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>general</div>
              </div>
            </v-tab>
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>detalle</div>
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <div v-if="tab == 0" class="mb-10">
          <v-row class="mt-5">
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Fecha'"
                :icon="'mdi-calendar'"
                :val="formatDate(invoice.date)"
              />
            </v-col>
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Sede'"
                :icon="'mdi-map-marker'"
                :val="invoice.location ? invoice.location.name : '-'"
              />
            </v-col>
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Consulta'"
                :icon="'mdi-calendar-account'"
                :val="invoice.appointment ? invoice.appointment.reason : '-'"
              />
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Última actualización'"
                :icon="'mdi-update'"
                :val="formatDateAndTime(invoice.updatedAt)"
              />
            </v-col>
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Creado por'"
                :icon="'mdi-account'"
                :val="
                  invoice.createdBy.firstName + ' ' + invoice.createdBy.lastName
                "
              />
            </v-col>
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Moneda'"
                :icon="'mdi-cash-100'"
                :val="invoice.currency || 'DOP'"
              />
            </v-col>
          </v-row>

          <v-row class="mt-5">
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Pagado'"
                :icon="'mdi-cash-plus'"
                :val="formatMoney(invoice.total - pending)"
              />
            </v-col>
            <v-col md="4" cols="12">
              <InfoItem
                :label="'Pendiente de pagar'"
                :icon="'mdi-currency-usd'"
                :chipVals="[
                  {
                    color: pending > 0 ? 'error' : '',
                    text: formatMoney(pending),
                  },
                ]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <InfoItem
                :label="'Observaciones y/o comentarios'"
                :icon="'mdi-card-text'"
                :val="invoice.comment"
              />
            </v-col>
          </v-row>
        </div>

        <v-row v-if="tab == 1" class="mb-5">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="invoice.items"
              :items-per-page="invoice.items.length"
              disable-sort
              item-key="id"
              loading-text="Cargando..."
              no-data-text="Sin datos..."
              item-class="text-center"
              hide-default-footer
            >
              <template slot="item.price" slot-scope="props">
                {{ formatMoney(props.item.price) }}
              </template>
              <template slot="item.descount" slot-scope="props">
                <div>{{ props.item.descountPercent }}%</div>
              </template>
              <template slot="item.import" slot-scope="props">
                {{ formatMoney(calculateImport(props.item)) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <!--<v-col md="6" cols="12" class="d-md-inline d-none">
            <h4 class="pasive--text font-weight-regular">
              En efectivo:
              {{ formatMoney(invoice.cash) }}
            </h4>
            <h4 class="pasive--text font-weight-regular">
              En transferencia o cheque:
              {{ formatMoney(invoice.bank) }}
            </h4>
            <h4 class="pasive--text font-weight-regular">
              En tarjeta crédito o débio:
              {{ formatMoney(invoice.creditCard) }}
            </h4>
          </v-col> -->
          <v-col md="6" cols="12">
            <h4 class="d-md-flex pasive--text font-weight-regular">
              <span>Subtotal:</span>
              <span class="ml-1">{{ formatMoney(subtotal) }}</span>
            </h4>
            <h4 class="d-md-flex pasive--text font-weight-regular">
              <span>Descuento:</span>
              <span class="ml-1">{{ formatMoney(descount.total) }}</span>
              <span class="ml-1">({{ descount.percent }}%)</span>
            </h4>
            <h3 class="d-md-flex">
              <span class="font-weight-regular">Total:</span>
              <span class="font-weight-bold ml-1">
                {{ formatMoney(invoice.total) }}
              </span>
            </h3>
          </v-col>
        </v-row>
        <v-row class="mt-5 d-flex align-center justify-sm-end justify-start">
          <v-btn
            v-if="
              currentUser.doctor.subscription == 'premium' ||
              currentUser.doctor.subscription == 'pro'
            "
            depressed
            small
            class="ma-2 d-block"
            @click="setToSendEmail()"
            :loading="sendingInvoice"
          >
            <v-icon small class="mr-1">mdi-email</v-icon>
            enviar por email
          </v-btn>
          <v-btn
            v-if="pending > 0"
            depressed
            class="ma-2 d-block"
            small
            @click="showCreateInvoicePaymentForm()"
          >
            <v-icon small class="mr-1">mdi-cash-plus</v-icon>
            realizar pago
          </v-btn>
          <v-btn depressed class="ma-2" small @click="setToEdit()">
            <v-icon small class="mr-1">mdi-square-edit-outline</v-icon>
            modificar
          </v-btn>
          <v-btn
            color="error"
            class="ma-2"
            depressed
            small
            @click="setToDelete"
          >
            <v-icon small class="mr-1">mdi-delete</v-icon>
            eliminar
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import moment from "moment";
import { formatCurrency, formatInvoiceId } from "../../helpers/formatters";
import InvoiceForm from "@/components/Payment/InvoiceForm";
import WarningDialog from "../WarningDialog.vue";
import InfoItem from "../InfoItem.vue";
import InvoiceBanner from "./InvoiceBanner.vue";
import InvoicePaymentForm from "./InvoicePaymentForm.vue";
import PatientsList from "../Patient/PatientsList.vue";
import Title from "../Title.vue";

export default {
  name: "Invoice",
  components: {
    InvoiceForm,
    WarningDialog,
    InfoItem,
    InvoiceBanner,
    PatientsList,
    InvoicePaymentForm,
    Title,
  },
  data() {
    return {
      tab: 0,
      appointment: null,
      patient: null,
      invoice: null,
      showPaymentForm: false,
      showInvoicePaymentForm: false,
      showPaymentDetails: false,
      deletingPayment: false,
      choosePatient: false,
      sendingInvoice: false,
      warningAction: "",
      headers: [
        { text: "Descripción", value: "product.name" },
        { text: "Cantidad", value: "quantity", align: "center" },
        { text: "Precio", value: "price", align: "center" },
        { text: "Descuento", value: "descount", align: "center" },
        { text: "Importe", value: "import", align: "center" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentLocation",
      "locations",
      "availableCurrencies",
    ]),
    paymentState() {
      if (this.invoice.total !== this.invoice.totalPaid) {
        return {
          icon: "mdi-error",
          text: "Incompleto",
        };
      } else {
        return {
          icon: "mdi-check",
          text: "Completado",
        };
      }
    },
    subtotal() {
      const items = this.invoice?.items;
      let sum = 0;
      for (let item of items) {
        sum += parseFloat(item.price) * parseFloat(item.quantity);
      }

      return sum;
    },
    descount() {
      const total = this.invoice?.total;
      const subtotal = this.subtotal;

      const descount = subtotal - total;
      const percent = subtotal ? (descount * 100) / subtotal : 0;

      return {
        percent: percent.toFixed(2),
        total: descount,
      };
    },
    pending() {
      const total = Number(this.invoice?.total);
      const totalPaid = Number(this.invoice?.totalPaid);
      return total - totalPaid;
    },
    loadingWarning() {
      return this.deletingPayment || this.sendingInvoice;
    },
    currentCurrencyRate() {
      return this.currencyRate
        ? Number(this.invoice.currencyRate)
        : Number(
            this.currencies.filter((c) => c.code == this.currency)[0]?.rate || 1
          );
    },
  },
  methods: {
    ...mapMutations(["setAlert", "updateAppointment"]),
    formatInvoiceCurrency(n) {
      if (this.invoice.currency && this.invoice.currency !== "DOP") {
        n = n / Number(this.invoice.currencyRate);
      }
      const currency = this.availableCurrencies.filter(
        (c) => c.value == this.invoice.currency
      )[0];
      return currency
        ? formatCurrency(n, currency.locale, currency.value)
        : formatCurrency(n);
    },
    formatInvoiceId(id) {
      return formatInvoiceId(id);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateAndTime(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    formatMoney(x) {
      return this.formatInvoiceCurrency(x);
    },
    calculateImport(item) {
      const price = parseFloat(item.price);
      const quantity = parseFloat(item.quantity);
      const descount = item.descountPercent
        ? parseFloat(item.descountPercent) / 100
        : 0;

      const itemTotal = quantity * price - quantity * price * descount;
      return itemTotal;
    },
    showInfo() {
      this.showPaymentDetails = true;
    },
    chosenPatient(patient) {
      this.patient = patient;
      this.choosePatient = false;
      this.showCreateForm();
    },
    showCreateInvoicePaymentForm() {
      this.showInvoicePaymentForm = true;
      this.$nextTick(() => {
        this.$refs.invoicePaymentFormRef.title = "Realizar pago";
        this.$refs.invoicePaymentFormRef.action = "create";
        this.$refs.invoicePaymentFormRef.cash = "";
        this.$refs.invoicePaymentFormRef.creditCard = "";
        this.$refs.invoicePaymentFormRef.bank = "";
        this.$refs.invoicePaymentFormRef.comment = "";
      });
    },
    handleInvoiceCreated({ invoice }) {
      this.invoice = invoice;
      this.$emit("invoice-created", { invoice });

      this.handleInvoiceChange({ invoice });

      this.showPaymentForm = false;
    },
    handleInvoiceUpdated({ invoice }) {
      this.invoice = invoice;
      this.$emit("invoice-updated", { invoice });

      this.handleInvoiceChange({ invoice });

      this.showPaymentForm = false;
    },

    handleNewInvoiceTotalPaid(newInvoiceTotalPaid) {
      const invoice = { ...this.invoice, totalPaid: newInvoiceTotalPaid };
      this.invoice = invoice;
      this.$emit("invoice-updated", { invoice });
      this.handleInvoiceChange({ invoice });
    },

    handleInvoicePaymentCreated({ item, newInvoiceTotalPaid }) {
      this.handleNewInvoiceTotalPaid(newInvoiceTotalPaid);
      this.showInvoicePaymentForm = false;
    },

    handleInvoicePaymentUpdated({ item }) {
      this.handleNewInvoiceTotalPaid(newInvoiceTotalPaid);
      this.showInvoicePaymentForm = false;
    },

    handleInvoiceChange({ invoice }) {
      if (this.appointment && this.appointment.invoices) {
        this.appointment.invoices = [invoice];
        this.updateAppointment(this.appointment);
        this.$emit("appointment-updated", this.appointment);
      }
    },
    showCreateForm() {
      this.showPaymentForm = true;
      this.$nextTick(() => {
        this.$refs.invoiceFormRef.title =
          "Facturar a " + this.patient.firstName;
        this.$refs.invoiceFormRef.sendEmail = this.patient.email ? true : false;
        this.$refs.invoiceFormRef.disableSendEmail = this.patient.email
          ? false
          : true;
        this.$refs.invoiceFormRef.action = "create";
        this.$refs.invoiceFormRef.setInputsValues({
          invoice: null,
          patient: this.patient,
          appointment: this.appointment,
        });
      });
    },
    setToCreate() {
      if (!this.patient) {
        this.choosePatient = true;
        return false;
      }
      this.showCreateForm();
    },
    setToEdit() {
      this.showPaymentForm = true;
      this.$nextTick(() => {
        this.$refs.invoiceFormRef.title = "Actualizar";
        this.$refs.invoiceFormRef.action = "update";
        this.$refs.invoiceFormRef.display = 1;
        this.$refs.invoiceFormRef.setInputsValues({
          invoice: this.invoice,
          patient: this.patient,
          appointment: this.appointment,
        });
      });
    },
    setToDelete() {
      this.warningAction = "delete";
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar este registro?`;
      this.$refs.WarningDialogRef.color = "error";
      this.$refs.WarningDialogRef.btnText = "Eliminar";
      this.$refs.WarningDialogRef.show = true;
    },
    setToSendEmail() {
      if (this.invoice.emailSent) {
        this.warningAction = "send-email";
        this.$refs.WarningDialogRef.description = `Esta factura ha sido enviada anteriormente ¿Seguro que deseas reenviarla al paciente?`;
        this.$refs.WarningDialogRef.color = "dark_primary";
        this.$refs.WarningDialogRef.btnText = "Enviar";
        this.$refs.WarningDialogRef.show = true;
      } else {
        this.sendInvoiceToPatient();
      }
    },
    handleWarningAction() {
      if (this.warningAction == "delete") {
        this.deletePayment();
      }

      if (this.warningAction == "send-email") {
        this.sendInvoiceToPatient();
      }
    },
    async deletePayment() {
      try {
        this.deletingPayment = true;

        const payload = {
          doctorId: this.currentUser.doctor.id,
        };

        const res = await axios.delete(`/api/invoice/${this.invoice.id}`, {
          data: payload,
        });

        if (res.data.message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: res.data.message,
          });
        }

        if (this.appointment) {
          this.appointment.invoices = [];
          this.updateAppointment(this.appointment);
        }

        this.$emit("invoice-deleted", { invoice: this.invoice });
        this.$refs.WarningDialogRef.show = false;
        this.showPaymentDetails = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deletingPayment = false;
      }
    },
    async sendInvoiceToPatient() {
      try {
        this.sendingInvoice = true;
        const res = await axios.post(`/api/invoice/share`, {
          invoiceId: this.invoice.id,
        });
        if (res.data.message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: res.data.message,
          });
        }
        this.invoice.emailSent = true;
        this.$emit("invoice-updated", {
          invoice: { ...this.invoice },
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.sendingInvoice = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
  },
};
</script>
