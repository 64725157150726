import moment from "moment";

// calculate agen base in a born date
export const calculateAge = (bornDate) => {
  if (!bornDate) {
    return "-";
  }

  const years = moment().diff(bornDate, "years");
  const months = moment().diff(bornDate, "months");
  const weeks = moment().diff(bornDate, "week");
  const days = moment().diff(bornDate, "days");

  if (years >= 1) {
    const yStr = years === 1 ? `1 año` : `${years} años`;
    return yStr + " de edad";
  }

  if (months >= 1) {
    const mStr = months === 1 ? `${months} mes` : `${months} meses`;
    return mStr + " de edad";
  }

  if (weeks >= 1) {
    const wStr = weeks === 1 ? `${weeks} semana` : `${weeks} semanas`;
    return wStr + " de edad";
  }

  if (days >= 1) {
    const dStr = days === 1 ? `${days} día` : `${days} días`;
    return dStr + " de edad";
  }

  return "Recien nacido";
};

export const formatHeightLabel = (item) => {
  if (!item.height) {
    return "";
  }

  if (item.heightMeasure === "ft") {
    return item.height + "' " + item.inchHeight + '"';
  }

  return item.height + " " + item.heightMeasure;
};

export const convertToMeters = (value, measure) => {
  // cm to mt
  if (measure === "cm") {
    return value / 100;
  }

  // ft to mt
  if (measure === "ft") {
    return value / 3.28084;
  }

  // inch to mt
  if (measure === "inch") {
    return value / 39.3700787;
  }

  // mt to mt
  if (measure === "mt") {
    return value;
  }
};

export const convertToKilo = (value, measure) => {
  // lb to kg
  if (measure === "lb") {
    return value / 2.2046;
  }

  if (measure === "kg") {
    return value;
  }
};

// height is in meter and weight is in kilo
export const calculateIMC = (height, weight) => {
  return parseFloat(weight / (height * height)).toFixed(3);
};

export const convertAndCalculateImc = (item) => {
  if (item.height && item.weight) {
    let height = convertToMeters(item.height, item.heightMeasure);

    if (item.heightMeasure === "ft" && item.inchHeight) {
      const secondHeight = convertToMeters(item.inchHeight, "inch");
      height += secondHeight;
    }

    let weight = convertToKilo(item.weight, item.weightMeasure);
    let result = calculateIMC(height, weight);
    return result;
  } else {
    return 0;
  }
};

export const passwordValidation = (value) => {
  const regularExpression =
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  return regularExpression.test(value);
};

export const diffBetweenTimes = (time1, time2) => {
  // Convert times to Moment.js objects
  const momentTime1 = moment(time1, "hh:mm A");
  const momentTime2 = moment(time2, "hh:mm A");

  // Calculate difference in minutes
  const minutes = momentTime2.diff(momentTime1, "minutes");
  const str = minutes === 1 ? minutes + " minuto" : minutes + " minutos";

  return str;
};

export const getTimeInSeconds = (time) => {
  console.log("time", time);
  if (!time) {
    return 0;
  }

  const [timeStr, period] = time.split(" ");
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);

  let totalSeconds = hours * 3600 + minutes * 60 + seconds;

  // Convert 12-hour format to 24-hour format
  if (period === "PM" && hours !== 12) {
    totalSeconds += 12 * 3600;
  }
  if (period === "AM" && hours === 12) {
    totalSeconds -= 12 * 3600;
  }

  return totalSeconds;
};

export const getDifferenceInSeconds = (start, end) => {
  const startInSeconds = getTimeInSeconds(start);
  const endInSeconds = getTimeInSeconds(end);
  return endInSeconds - startInSeconds;
};

export const formatDuration = (seconds) => {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const parts = [];
  if (days > 0) parts.push(`${days} día${days > 1 ? "s" : ""}`);
  if (hours > 0) parts.push(`${hours} hora${hours > 1 ? "s" : ""}`);
  if (minutes > 0) parts.push(`${minutes} minuto${minutes > 1 ? "s" : ""}`);
  if (seconds > 0 || parts.length === 0)
    parts.push(`${seconds} segundo${seconds > 1 ? "s" : ""}`);

  return parts.join(", ");
};
