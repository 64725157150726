import axios from "axios";

const state = {
  patients: [],
};

const getters = {
  patients: (state) => state.patients,
};

const actions = {
  async fetchPatients({ commit, rootState }, params) {
    try {
      const doctorID = rootState.currentUser.currentUser.doctor.id;
      const { data } = await axios.get(`/api/patients/${doctorID}`, { params });
      commit("setPatients", data.items);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPatientFiles({ commit }, id) {
    try {
      const { data } = await axios.get(`/api/patient/${id}/files`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};

const mutations = {
  setPatients: (state, patients) => (state.patients = patients),
  pushPatient: (state, patient) => state.patients.push(patient),
  updatePatient: (state, patient) => {
    const index = state.patients.findIndex((p) => p.id === patient.id);
    if (index !== -1) Object.assign(state.patients[index], patient);
  },
  removePatient: (state, patient) => {
    state.patients = state.patients.filter((d) => {
      return d.id !== patient.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
