import axios from "axios";

const state = {
  doctors: [],
  attending: false,
  baseDoctorForms: [],
  doctorForms: [],
  doctorFormModules: [],
  doctorTemplates: [],
  locations: [],
  currentLocation: null,
  currencies: [],
  currentCurrency: null,
};

const getters = {
  doctors: (state) => state.doctors,
  attending: (state) => state.attending,
  doctorForms: (state) => state.doctorForms,
  doctorFormModules: (state) => state.doctorFormModules,
  doctorTemplates: (state) => state.doctorTemplates,
  locations: (state) => state.locations,
  currentLocation: (state) => state.currentLocation,
  currencies: (state) => state.currencies,
  currentCurrency: (state) => state.currentCurrency,
};

const actions = {
  async fetchDoctors({ commit }, params) {
    try {
      const res = await axios.get("/api/doctors", { params });
      return res.data;
      commit("setDoctors", res.data.doctors);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchDoctorStats({ commit, rootState }, params) {
    try {
      const { data } = await axios.get(`/api/doctor/${params.doctorId}/stats`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  initDoctor({ commit }, doctor) {
    //set doctor attending status in global state
    commit("setAttendingStatus", doctor.attending || false);

    //set doctor clinical forms in global state
    const forms = state.baseDoctorForms.concat(doctor.forms || []);
    commit("setDoctorForms", forms);

    //set doctor form modules in global state
    commit("setDoctorFormModules", doctor.formModules || []);

    //set doctor locations in global state
    commit("setLocations", doctor.locations || []);

    //set doctor locations in global state
    commit("setCurrencies", doctor.currencies || []);

    //set doctor clinical templates in global state
    commit("setDoctorTemplates", doctor.templates || []);
  },
};

const mutations = {
  setDoctors: (state, doctors) => (state.doctors = doctors),
  setAttendingStatus: (state, status) => (state.attending = status),
  setDoctorForms: (state, forms) => (state.doctorForms = forms),

  setCurrentCurrency: (state, data) => (state.currentCurrency = data),
  setCurrencies: (state, data) => (state.currencies = data),
  pushCurrency: (state, data) => state.currencies.push(data),
  updateCurrency: (state, item) => {
    const index = state.currencies.findIndex((p) => p.id === item.id);
    if (index !== -1) Object.assign(state.currencies[index], item);
  },
  removeCurrency: (state, item) => {
    state.currencies = state.currencies.filter((d) => {
      return d.id !== item.id;
    });
  },

  setCurrentLocation: (state, data) => (state.currentLocation = data),
  setLocations: (state, data) => (state.locations = data),
  pushLocation: (state, data) => state.locations.push(data),
  updateLocation: (state, item) => {
    const index = state.locations.findIndex((p) => p.id === item.id);
    if (index !== -1) Object.assign(state.locations[index], item);
  },
  removeLocation: (state, item) => {
    state.locations = state.locations.filter((d) => {
      return d.id !== item.id;
    });
  },

  setDoctorFormModules: (state, modules) => (state.doctorFormModules = modules),
  pushDoctorFormModules: (state, module) =>
    state.doctorFormModules.push(module),
  updateDoctorFormModules: (state, item) => {
    const index = state.doctorFormModules.findIndex((p) => p.id === item.id);
    if (index !== -1) Object.assign(state.doctorFormModules[index], item);
  },
  removeDoctorFormModules: (state, item) => {
    state.doctorFormModules = state.doctorFormModules.filter((d) => {
      return d.id !== item.id;
    });
  },

  setDoctorTemplates: (state, templates) => (state.doctorTemplates = templates),
  pushDoctorTemplates: (state, template) => {
    state.doctorTemplates.push(template);
  },
  pushDoctor: (state, doctor) => state.doctors.push(doctor),
  updateDoctor: (state, doctor) => {
    const index = state.doctors.findIndex((d) => d.id === doctor.id);
    if (index !== -1) {
      Object.assign(state.doctors[index], doctor);
    }
  },
  removeDoctor: (state, doctor) => {
    state.doctors = state.doctors.filter((d) => {
      return d.id !== doctor.id;
    });
  },
  pushDoctorForm: (state, form) => state.doctorForms.push(form),
  updateDoctorForm: (state, form) => {
    const index = state.doctorForms.findIndex((p) => p.id === form.id);
    if (index !== -1) Object.assign(state.doctorForms[index], form);
  },
  removeDoctorForm: (state, form) => {
    state.doctorForms = state.doctorForms.filter((d) => {
      return d.id !== form.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
