const state = {
    currentUser: null,
};

const getters = {
    currentUser: state => state.currentUser,
};

const mutations = {
    setCurrentUser: (state, user) => (state.currentUser = user),
};

export default {
    state,
    getters,
    mutations
};
