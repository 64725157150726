<template>
  <div>
    {{ formattedTime }}
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      start: "08:30:15", // Set your desired start time in 'HH:mm:ss' format
      isRunning: false,
      elapsedTimeInSeconds: 0,
      startTime: null,
    };
  },
  computed: {
    ...mapGetters(["attending"]),
    formattedTime() {
      const hours = Math.floor(this.elapsedTimeInSeconds / 3600);
      const minutes = Math.floor((this.elapsedTimeInSeconds % 3600) / 60);
      const seconds = this.elapsedTimeInSeconds % 60;

      const hoursPad = hours > 0 ? `${String(hours).padStart(2, "0")}:` : "";
      const minutesPad = `${String(minutes).padStart(2, "0")}`;
      const secondsPad = `:${String(seconds).padStart(2, "0")}`;

      return hoursPad + minutesPad + secondsPad;
    },
  },
  methods: {
    startChronometer() {
      if (!this.isRunning) {
        const currentTime = new Date();
        const startTimeParts = this.start.split(":");
        const startHour = parseInt(startTimeParts[0]);
        const startMinute = parseInt(startTimeParts[1]);
        const startSecond = parseInt(startTimeParts[2]);

        const startDateTime = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          startHour,
          startMinute,
          startSecond
        );

        // Calculate the elapsed time in seconds
        const elapsedMilliseconds = currentTime - startDateTime;
        this.elapsedTimeInSeconds = Math.floor(elapsedMilliseconds / 1000);

        this.startTime = startDateTime;
        this.isRunning = true;

        this.timerInterval = setInterval(() => {
          this.elapsedTimeInSeconds++;
        }, 1000);
      }
    },
    stopChronometer() {
      if (this.isRunning) {
        this.isRunning = false;
        clearInterval(this.timerInterval);
      }
    },
    resetChronometer() {
      this.isRunning = false;
      this.elapsedTimeInSeconds = 0;
      this.startTime = null;
    },
  },
  watch: {
    attending: {
      immediate: true,
      handler(currentAttendingAppointment) {
        if (currentAttendingAppointment) {
          console.log(
            "currentAttendingAppointment.start",
            currentAttendingAppointment.start
          );
          this.start = moment(
            currentAttendingAppointment.start,
            "HH:mm:ss A"
          ).format("HH:mm:ss");
          this.startChronometer();
        } else {
          this.stopChronometer();
          this.resetChronometer();
        }
      },
    },
  },
};
</script>
