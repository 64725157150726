<template>
  <div>
    <h2 class="title secondary--text">{{ title }}</h2>
    <v-divider class="mt-2"></v-divider>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: ["title"],
};
</script>
