import axios from 'axios'

const state = {
    secretaries: []
};

const getters = {
    secretaries: state => state.secretaries,
};

const actions = {
    async fetchUsersByDoctor({ commit }, { doctorID }){
        const res = await axios.get(`/api/secretariesByDoctor/${doctorID}`)
        console.log(res)
        commit('setSecretaries', res.data.secretaries);
    },
};

const mutations = {
    setSecretaries: (state, users) => (state.secretaries = users),
    pushSecretary: (state, user) => (state.secretaries.push(user)),
    updateSecretary: (state, user) => {
        const index = state.secretaries.findIndex((u => u.id === user.id))
        Object.assign(state.secretaries[index], user);
    },
    removeSecretary: (state, user) => {
        state.secretaries = state.secretaries.filter((u) => { 
            return u.id !== user.id;
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};