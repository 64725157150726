<template>
  <v-chip
    @click="handleClick"
    v-if="attending"
    color="primary"
    class="font-weight-medium"
    style="cursor: pointer"
  >
    <div class="mr-2 d-none d-sm-flex">
      {{ label }}
    </div>
    <AttendingChronometer />
  </v-chip>
</template>

<script>
import { mapGetters } from "vuex";
import AttendingChronometer from "./AttendingChronometer.vue";

export default {
  name: "AttendingChip",
  components: {
    AttendingChronometer,
  },
  computed: {
    ...mapGetters(["attending"]),
    label() {
      return this.attending?.patient?.firstName;
    },
  },
  methods: {
    handleClick() {
      this.$router.push("/agenda").catch((error) => {
        if (
          error?.message?.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          this.goToPatientPage();
        }
      });
    },
    goToPatientPage() {
      if (this.attending?.patient) {
        this.$router.push("/paciente/" + this.attending?.patient?.id);
      }
    },
  },
};
</script>
