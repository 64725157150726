import axios from "axios";

const state = {
  invoicePayments: [],
  invoicePaymentsCount: 0,
  invoicePaymentsQueryParams: null,
};

const getters = {
  invoicePayments: (state) => state.invoicePayments,
  invoicePaymentsCount: (state) => state.invoicePaymentsCount,
  invoicePaymentsQueryParams: (state) => state.invoicePaymentsQueryParams,
};

const actions = {
  async fetchInvoicePayments({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/invoice_payments/`, {
        params,
      });
      commit("setInvoicePaymentsQueryParams", params);
      commit("setInvoicePayments", data?.items || []);
      commit("setInvoicePaymentsCount", data?.count || 0);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  refreshInvoicePayments({ dispatch, getters }) {
    dispatch("fetchInvoicePayments", getters.invoicePaymentsQueryParams);
  },
  async fetchInvoicePaymentStats({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/invoice_payments/stats`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchInvoicePaymentDateSeries({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/invoice_payments/date_series`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};

const mutations = {
  setInvoicePayments: (state, invoicePayments) => (state.invoicePayments = invoicePayments),
  setInvoicePaymentsCount: (state, data) => (state.invoicePaymentsCount = data),
  setInvoicePaymentsQueryParams: (state, data) => (state.invoicePaymentsQueryParams = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
