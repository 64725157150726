<template>
  <v-text-field
    v-model="text"
    label="Buscar"
    append-icon="mdi-magnify"
    @click:append="search"
    @keypress.enter="search"
    @click:clear="clear"
    clearable
    outlined
    dense
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchInput",
  data() {
    return {
      text: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.text);
    },
    clear() {
      this.$emit("search", "");
    },
  },
};
</script>
