<template>
  <v-dialog v-model="loadingOverlay" width="225" persistent>
    <v-card class="pa-10 d-flex flex-column align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoadingOverlay",
  computed: {
    ...mapGetters(["loadingOverlay"]),
  },
};
</script>
