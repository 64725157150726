import axios from "axios";

const state = {
  products: [],
};

const getters = {
  products: (state) => state.products,
};

const actions = {
  async fetchProducts({ commit }, params) {
    try {
      const { data } = await axios.get(`/api/products`, { params });
      commit("setProducts", data.items);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setProducts: (state, products) => (state.products = products),
  pushProduct: (state, product) => {
    if (state.products.length == 0) {
      state.products = [product];
    } else {
      state.products.push(product);
    }
  },
  updateProduct: (state, product) => {
    const index = state.products.findIndex((p) => p.id === product.id);
    if (index !== -1) Object.assign(state.products[index], product);
  },
  updateProductStock: (state, { id, stock }) => {
    const index = state.products.findIndex((p) => p.id === id);
    if (index !== -1)
      Object.assign(state.products[index], { ...state.products[index], stock });
  },
  removeProduct: (state, product) => {
    state.products = state.products.filter((d) => {
      return d.id !== product.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
