import axios from "axios";

const state = {
  forms: [],
};

const getters = {
  forms: (state) => state.forms,
};

const actions = {
  async fetchForms({ commit }) {
    const res = await axios.get(`/api/forms`);
    commit("setForms", res.data.forms);
  },
};

const mutations = {
  setForms: (state, forms) => (state.forms = forms),
  pushForm: (state, Form) => state.forms.push(Form),
  updateForm: (state, Form) => {
    const index = state.forms.findIndex((p) => p.id === Form.id);
    if (index !== -1) Object.assign(state.forms[index], Form);
  },
  removeForm: (state, Form) => {
    state.forms = state.forms.filter((d) => {
      return d.id !== Form.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
