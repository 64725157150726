import Vue from "vue";
import Vuex from "vuex";
import turns from "./modules/turns";
import admins from "./modules/admins";
import data from "./modules/data";
import doctors from "./modules/doctors";
import users from "./modules/users";
import currentUser from "./modules/currentUser";
import components from "./modules/components";
import specialties from "./modules/specialties";
import secretaries from "./modules/secretaries";
import patients from "./modules/patients";
import appointments from "./modules/appointments";
import prints from "./modules/prints.store";
import medicalHistory from "./modules/medicalHistory.store";
import ars from "./modules/ars.store";
import form from "./modules/forms.store";
import template from "./modules/template.store";
import formModule from "./modules/formModule.store";
import invoices from "./modules/invoices";
import contacts from "./modules/contacts.store";
import invoicePaymentStore from "./modules/invoicePayment.store";
import product from "./modules/product";
import expense from "./modules/expense";
import finance from "./modules/finance.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    data,
    invoices,
    components,
    turns,
    admins,
    doctors,
    users,
    currentUser,
    specialties,
    secretaries,
    patients,
    appointments,
    prints,
    medicalHistory,
    ars,
    form,
    template,
    formModule,
    contacts,
    invoicePaymentStore,
    product,
    expense,
    finance,
  },
});
