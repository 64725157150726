const state = {
    // prints types
    medicationPrint: {
        prescriptions: [],
        patient: {
            firstName: '',
            lastName: '',
            gender: '',
            doctor: {
                user: {
                    firstName: '',
                    lastName: '',
                },
                gender: ''
            }
        }
    },

    // show or not a print
    showPrint: {},
};

const getters = {
    medicationPrint: state => state.medicationPrint,
    showPrint: state => state.showPrint,
};

const mutations = {
    setMedicationPrint: (state, data) => (state.medicationPrint = data),
    setShowPrint: (state, data) => (state.showPrint = data),
};

export default {
    state,
    getters,
    mutations
};