import axios from "axios";
import moment from "moment";

const state = {
  todayAppointments: [],
  appointments: [],
  tableSelected: [],
  loadingAppointments: false,
  waitingDateSelected: null,
  queryParams: null,
  attendingAppointment: {
    id: -1,
  },
};

const getters = {
  todayAppointments: (state) => state.todayAppointments,
  appointments: (state) => state.appointments,
  tableSelected: (state) => state.tableSelected,
  loadingAppointments: (state) => state.loadingAppointments,
  attendingAppointment: (state) => state.attendingAppointment,
  waitingDateSelected: (state) => state.waitingDateSelected,
  queryParams: (state) => state.queryParams,
};

const actions = {
  async fetchAppointmentsByDoctor({ commit }, params) {
    try {
      commit("setLoadingAppointments", true);
      commit("setQueryParams", params);

      const { data } = await axios.get(`/api/appointments/`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      commit("setLoadingAppointments", false);
    }
  },
  refreshAppointments({ dispatch, getters }) {
    dispatch("fetchAppointmentsByDoctor", getters.queryParams);
  },
  // today waiting dates
  async fetchTodayWaitingAppointments({ commit, rootState }) {
    try {
      commit("setLoadingAppointments", true);
      const today = moment().startOf("day").toDate().toJSON().slice(0, 10);
      const { data } = await axios.get(`/api/appointments/`, {
        params: {
          dateFrom: today,
          dateTo: today,
          doctorId: rootState.currentUser.currentUser.doctor.id,
          locationId: rootState.doctors.currentLocation?.id,
          status: "en espera",
        },
      });
      const { items } = data;
      commit("setTodayAppointments", items);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      commit("setLoadingAppointments", false);
    }
  },
  async fetchAppointmentStats({ commit, rootState }, params) {
    try {
      const { data } = await axios.get(`/api/appointments/stats`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};

const mutations = {
  setTodayAppointments: (state, appointments) =>
    (state.todayAppointments = appointments),
  setAppointments: (state, appointments) => (state.appointments = appointments),
  setWaitingDateSelected: (state, date) => (state.waitingDateSelected = date),
  pushAppointment: (state, appointment) => {
    if (
      state.appointments.filter((a) => a.id === appointment.id).length === 0
    ) {
      state.appointments.push(appointment);
    }
  },
  unshiftAppointment: (state, appointment) => {
    if (
      state.appointments.filter((a) => a.id === appointment.id).length === 0
    ) {
      state.appointments.unshift(appointment);
    }
  },
  pushTodayAppointment: (state, appointment) => {
    if (
      state.todayAppointments.filter((a) => a.id === appointment.id).length ===
      0
    ) {
      state.todayAppointments.push(appointment);
    }
  },
  updateAppointment: (state, appointment) => {
    state.appointments = state.appointments.map((a) => {
      if (a.id === appointment.id) {
        return appointment;
      }
      return a;
    });

    state.todayAppointments = state.todayAppointments.map((a) => {
      if (a.id === appointment.id) {
        return appointment;
      }
      return a;
    });
  },
  removeAppointment: (state, appointment) => {
    state.appointments = state.appointments.filter(
      (a) => a.id !== appointment.id
    );
    state.todayAppointments = state.todayAppointments.filter(
      (a) => a.id !== appointment.id
    );
  },
  updateAppointmentPatient: (state, patient) => {
    state.appointments = state.appointments.map((a) => {
      if (a.patient.id === patient.id) {
        Object.assign(a.patient, patient);
        return a;
      } else {
        return a;
      }
    });

    state.todayAppointments = state.todayAppointments.map((a) => {
      if (a.patient.id === patient.id) {
        Object.assign(a.patient, patient);
        return a;
      } else {
        return a;
      }
    });
  },
  setLoadingAppointments: (state, status) =>
    (state.loadingAppointments = status),
  setAttendingAppointment: (state, appointment) =>
    (state.attendingAppointment = appointment),
  setTableSelected: (state, val) => (state.tableSelected = val),
  setQueryParams: (state, data) => (state.queryParams = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
