import axios from "axios";

const state = {
  templates: [],
};

const getters = {
  templates: (state) => state.templates,
};

const actions = {
  async fetchTemplates({ commit }) {
    const res = await axios.get(`/api/templates`);
    commit("setTemplates", res.data.templates);
  },
};

const mutations = {
  setTemplates: (state, templates) => (state.templates = templates),
  pushTemplate: (state, Template) => state.templates.push(Template),
  updateTemplate: (state, template) => {
    const index = state.templates.findIndex((p) => p.id === template.id);
    if (index !== -1) Object.assign(state.templates[index], template);
  },
  removeTemplate: (state, template) => {
    state.templates = state.templates.filter((d) => {
      return d.id !== template.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
