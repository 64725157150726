import axios from 'axios'
import moment from 'moment'

const state = {
    turns: [],
    date: '',
    since: moment().startOf('day').toDate().toJSON().slice(0, 10), // current date
    till: moment().startOf('day').toDate().toJSON().slice(0, 10), // current date
    loadingTurns: false,
    nextTurnWarning: false,
    showEndShiftInfo: false,
};

const getters = {
    turnsData: state => state.turns,
    turnsDate: state => state.date,
    loadingTurns: state => state.loadingTurns,
    nextTurnWarning: state => state.nextTurnWarning,
    showEndShiftInfo: state => state.showEndShiftInfo,
    since: state => state.since,
    till: state => state.till,
};

const actions = {
    async fetchTurns({ commit }){
        try {
            const res = await axios.get('/api/todayTurns')
            if(res.data.turns){
                let turns = res.data.turns.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
                commit('setTurnsData', turns);
            } else {
                commit('setTurnsData', []);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async fetchTurnsByDate({ commit }){
        try {
            const res = await axios.post('/api/turnsListByDate', { date: state.date })
            if(res.data.turns){
                let turns = res.data.turns.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
                commit('setTurnsData', turns);
            } else {
                commit('setTurnsData', []);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async fetchTurnsBetweenDates({ commit }){
        try {
            const params = { since: state.since, till: state.till }
            const res = await axios.get('/api/turnsBetweenDates', { params });
            if(res.data.turns){
                let turns = res.data.turns.map( (turn) => {
                    // formating date
                    turn.date = turn.date.substr(0, 10)
                    return turn
                });
                commit('setTurnsData', turns);
            } else {
                commit('setTurnsData', []);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async fetchAllTurns({ commit }){
        try {
            const res = await axios.get('/api/turns');
            if(res.data.turns){
                let turns = res.data.turns.map( (turn) => {
                    // formating date
                    turn.date = turn.date.substr(0, 10)
                    return turn
                });
                commit('setTurnsData', turns);
            } else {
                commit('setTurnsData', []);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async endOfShift({ commit }){
        try {
            const res = await axios.post('/api/endOfShift', { turns: state.turns })
            if(res.data.turns){
                let turns = res.data.turns.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
                commit('setTurnsData', turns);
            } else {
                commit('setTurnsData', []);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async setStartTime({ commit }, turn){
        try {
            const res = await axios.post('/api/setStartTime', turn)
            commit('updateTurn', res.data.updatedTurn)
        } catch (error) {
            console.log(error)
        }
    },
    async setEndTime({ commit }, turn){
        try {
            const res = await axios.post('/api/setEndTime', turn)
            commit('updateTurn', res.data.updatedTurn)
        } catch (error) {
            console.log(error)
        }
    },
    insertTurn({ commit }, turn){
        commit('pushTurn', turn)
    },
    removeTurn({ commit }, code) {
        commit('deleteTurnByCode', code)
    },
    async fetchBetweenDatesOfPayment({ commit }){
        try {
            const params = { since: state.since, till: state.till }
            const res = await axios.get('/api/paymentBetweenDates', { params });
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    },
};

const mutations = {
    setLoadingTurns: (state, status) => (state.loadingTurns = status),
    setTurnsData: (state, turnsData) => (state.turns = turnsData),
    setDate: (state, date) => (state.date = date),
    updateTurns: (state, turnsData) => (state.turns = turnsData),
    pushTurn: (state, turn) => (state.turns.push(turn)),
    deleteTurnByCode: (state, code) => {
        state.turns = state.turns.filter((turn) => { 
            return turn.code !== code;
        });
    },
    updateTurn: (state, turn) => {
        const index = state.turns.findIndex((t => t._id === turn._id))
        turn.date = turn.date.substr(0, 10)
        Object.assign(state.turns[index], turn);
    },
    setNextTurnWarning: (state, status) => (state.nextTurnWarning = status),
    setEndShiftInfo: (state, status) => (state.showEndShiftInfo = status),
    setSinceDate: (state, date) => (state.since = date),
    setTillDate: (state, date) => (state.till = date),
};

export default {
    state,
    getters,
    actions,
    mutations
};