import axios from "axios";

const state = {
  formModules: [],
};

const getters = {
  formModules: (state) => state.formModules,
};

const actions = {
  async fetchFormModule({ commit }) {
    const res = await axios.get(`/api/formModules`);
    commit("setFormModule", res.data.formModules);
  },
};

const mutations = {
  setFormModule: (state, formModules) => (state.formModules = formModules),
  pushFormModule: (state, item) => state.formModules.push(item),
  updateFormModule: (state, item) => {
    const index = state.formModules.findIndex((p) => p.id === item.id);
    if (index !== -1) Object.assign(state.formModules[index], item);
  },
  removeFormModule: (state, template) => {
    state.formModules = state.formModules.filter((d) => {
      return d.id !== template.id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
