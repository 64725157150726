<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text small color="secondary" v-bind="attrs" v-on="on">
          <v-icon small class="mr-1"> mdi-shape-square-plus </v-icon>
          <span style="text-transform: capitalize;">
            Nuevo</span
          >
        </v-btn>
      </template>
      <v-list style="min-width: 200px">
        <v-list-item @click="handleClickAddAppointment" color="primary">
          <v-list-item-content class="subtitle-2 font-weight-regular d-flex">
            <div class="d-flex align-center">
              <div class="secondary--text">Consulta</div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showPatientForm = true" color="primary">
          <v-list-item-content class="subtitle-2 font-weight-regular d-flex">
            <div class="d-flex align-center">
              <div class="secondary--text">Paciente</div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleClickAddInvoice" color="primary">
          <v-list-item-content class="subtitle-2 font-weight-regular d-flex">
            <div class="d-flex align-center">
              <div class="secondary--text">Factura</div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <AppointmentForm
      ref="appointmentForm"
      :pickDate="true"
      @created="handleAppointmentCreated"
    />

    <Invoice ref="InvoiceRef" @invoice-created="refreshInvoices" />

    <v-dialog
      width="1200"
      v-model="showPatientForm"
      @keydown.esc="showPatientForm = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showPatientForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <PatientForm
          :title="'Crear'"
          @patient-created="showPatientForm = false"
          @on-cancel="showPatientForm = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import AppointmentForm from "@/components/Appointment/AppointmentForm";
import PatientForm from "../Patient/PatientForm.vue";
import Invoice from "../Payment/Invoice.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AddActionsBtn",
  components: {
    AppointmentForm,
    PatientForm,
    Invoice,
  },
  data() {
    return {
      showPatientForm: false,
    };
  },
  computed: {
    ...mapGetters(["currentLocation"]),
  },
  methods: {
    ...mapActions([
      "fetchTodayWaitingAppointments",
      "refreshAppointments",
      "refreshInvoices",
    ]),
    handleClickAddAppointment() {
      this.$refs.appointmentForm.action = "create";
      this.$refs.appointmentForm.time = null;
      this.$refs.appointmentForm.form = {
        patient: null,
        reason: "",
        estimatedTime: "",
        date: moment().format("YYYY-MM-DD"),
      };
      this.$refs.appointmentForm.choosePatient = true;
    },
    handleClickAddInvoice() {
      this.$refs.InvoiceRef.patient = null;
      this.$refs.InvoiceRef.setToCreate();
    },
    handleAppointmentCreated() {
      this.refreshAppointments();

      if (this.$route.path == "/agenda") {
        this.fetchTodayWaitingAppointments();
      }
    },
    handleInvoiceCreated() {
      this.refreshInvoices();
    },
  },
};
</script>
