<template>
  <v-dialog v-model="show" persistent @keydown.esc="show = false" width="unset">
    <v-card class="pa-sm-10 pa-5 text-center">
      <v-icon x-large :color="color"> {{ icon }} </v-icon>
      <h5 class="title text-center secondary--text mt-10">
        {{ description }}
      </h5>
      <v-row class="mt-10">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-btn
            @click="handleClick()"
            :color="color"
            :loading="loading"
            depressed
            dark
          >
            <span> {{ btnText }} </span>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-btn depressed @click="show = false" :loading="loading" >
            <span> Cancelar</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "WarningDialog",
  props: ["loading"],
  data() {
    return {
      show: false,
      description: "",
      color: "error",
      icon: "mdi-alert-circle",
      btnText: "Eliminar",
    };
  },
  methods: {
    handleClick() {
      this.$emit("action");
    },
  },
};
</script>
