var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('v-container',[_c('Title',{staticClass:"mb-8",attrs:{"title":_vm.title}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","readonly":"","outlined":"","value":_vm.formattedDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"locale":"es-ES","color":"primary"},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(_vm.invoice && _vm.invoice.currency == 'DOP')?_c('div',[(_vm.paymentMethod !== 'Mixto')?_c('vuetify-money',{attrs:{"label":"Importe a pagar","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        }},model:{value:(_vm.payment),callback:function ($$v) {_vm.payment=$$v},expression:"payment"}}):_vm._e(),_c('v-select',{attrs:{"items":[
          {
            text: 'Efectivo',
            value: 'Efectivo',
          },
          {
            text: 'Tarjeta de crédito/débito',
            value: 'Tarjeta de crédito/débito',
          },
          {
            text: 'Transferencia bancaria',
            value: 'Transferencia bancaria',
          },
          {
            text: 'Mixto',
            value: 'Mixto',
          },
        ],"label":"Método de pago","outlined":""},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}}),(_vm.paymentMethod == 'Mixto')?_c('vuetify-money',{attrs:{"label":"Efectivo","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        },"properties":{
          hint: '',
          appendIcon: 'mdi-cash',
        }},model:{value:(_vm.cash),callback:function ($$v) {_vm.cash=$$v},expression:"cash"}}):_vm._e(),(_vm.paymentMethod == 'Mixto')?_c('vuetify-money',{attrs:{"label":"Transferencia bancaria o cheque","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        },"properties":{
          hint: '',
          appendIcon: 'mdi-bank',
        }},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}}):_vm._e(),(_vm.paymentMethod == 'Mixto')?_c('vuetify-money',{attrs:{"label":"Tarjeta crédito/débito","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        },"properties":{
          hint: '',
          appendIcon: 'mdi-credit-card',
        }},model:{value:(_vm.creditCard),callback:function ($$v) {_vm.creditCard=$$v},expression:"creditCard"}}):_vm._e()],1):_c('div',[(_vm.paymentMethod !== 'Mixto')?_c('vuetify-money',{attrs:{"label":"Importe a pagar","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: _vm.currentCurrencyPrefix + ' ',
          suffix: '',
          length: 9,
          precision: 2,
        }},model:{value:(_vm.foreignPayment),callback:function ($$v) {_vm.foreignPayment=$$v},expression:"foreignPayment"}}):_vm._e(),_c('v-select',{attrs:{"items":[
          {
            text: 'Efectivo',
            value: 'Efectivo',
          },
          {
            text: 'Tarjeta de crédito/débito',
            value: 'Tarjeta de crédito/débito',
          },
          {
            text: 'Transferencia bancaria',
            value: 'Transferencia bancaria',
          },
          {
            text: 'Mixto',
            value: 'Mixto',
          },
        ],"label":"Método de pago","outlined":""},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}}),(_vm.paymentMethod == 'Mixto')?_c('vuetify-money',{attrs:{"label":"Efectivo","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: _vm.currentCurrencyPrefix + ' ',
          suffix: '',
          length: 9,
          precision: 2,
        },"properties":{
          hint: '',
          appendIcon: 'mdi-cash',
        }},model:{value:(_vm.foreignCash),callback:function ($$v) {_vm.foreignCash=$$v},expression:"foreignCash"}}):_vm._e(),(_vm.paymentMethod == 'Mixto')?_c('vuetify-money',{attrs:{"label":"Transferencia bancaria o cheque","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: _vm.currentCurrencyPrefix + ' ',
          suffix: '',
          length: 9,
          precision: 2,
        },"properties":{
          hint: '',
          appendIcon: 'mdi-bank',
        }},model:{value:(_vm.foreignBank),callback:function ($$v) {_vm.foreignBank=$$v},expression:"foreignBank"}}):_vm._e(),(_vm.paymentMethod == 'Mixto')?_c('vuetify-money',{attrs:{"label":"Tarjeta crédito/débito","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
          locale: 'ja-JP',
          prefix: _vm.currentCurrencyPrefix + ' ',
          suffix: '',
          length: 9,
          precision: 2,
        },"properties":{
          hint: '',
          appendIcon: 'mdi-credit-card',
        }},model:{value:(_vm.foreignCreditCard),callback:function ($$v) {_vm.foreignCreditCard=$$v},expression:"foreignCreditCard"}}):_vm._e()],1),_c('v-text-field',{attrs:{"type":"text","label":"Comentarios del pago","outlined":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('h4',{staticClass:"d-md-flex secondary--text mb-1"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("Total a pagar:")]),_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.totalToPay))+" ")])]),_c('h4',{staticClass:"d-md-flex secondary--text mb-1"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("Restante a pagar:")]),_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.leftToPay))+" ")])]),(_vm.exchange)?_c('h4',{staticClass:"d-md-flex secondary--text mb-1"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("Cambio:")]),_c('span',{staticClass:"font-weight-bold ml-1 error--text"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.exchange))+" ")])]):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-5",attrs:{"depressed":"","color":"primary","type":"submit","disabled":_vm.disableBtn,"loading":_vm.loading}},[_vm._v(" Guardar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }