const state = {
  alert: {},
  warning: {},
  from: "",
  to: "",
  loadingOverlay: false,
  subForms: [
    {
      code: '-1',
      description: "Antecedentes familiares",
      icon: "mdi-account-supervisor-circle",
      color: "secondary",
      tabs: [
        {
          text: "Antec. Familiares",
          description: "Antecedentes familiares",
          single: "Antecedente familiar",
          icon: "mdi-account-supervisor-circle",
          value: "family",
          key: "families",
          componentNames: {
            form: "family-background-form",
            record: "family-background-info-item",
            print: "family-background-print",
          },
          api: {
            delete: "/familybackground/",
          },
        },
      ],
    },
    {
      code: '-2',
      description: "Antecedentes personales no patológicos",
      color: "secondary",
      icon: "mdi-clipboard-account",
      single: "Antecedente personal no patológico",
      tabs: [
        {
          text: "Actividad física",
          description: "Actividad física",
          single: "Actividad física",
          icon: "mdi-run",
          value: "physical",
          key: "activities",
          componentNames: {
            form: "physical-activity-form",
            record: "physical-activity-info-item",
            print: "physical-activity-print",
          },
          api: {
            delete: "/physicalactivity/",
          },
        },
        {
          text: "Hábitos Tóxicos",
          description: "Hábitos Tóxicos",
          single: "Hábito Tóxico",
          icon: "mdi-liquor",
          value: "toxicHabit",
          key: "toxicHabits",
          componentNames: {
            form: "toxic-habit-form",
            record: "toxic-habit-info-item",
            print: "toxic-habit-print",
          },
          api: {
            delete: "/toxichabit/",
          },
        },
      ],
    },
    {
      code: '-3',
      description: "Antecedentes personales patológicos",
      icon: "mdi-account-injury",
      color: "secondary",
      single: "Antecedente personal patológico",
      tabs: [
        {
          text: "Antec. personales patológicos",
          description: "Antecedentes personales patológicos",
          single: "Antecedente personal patológico",
          icon: "mdi-account-injury",
          value: "diagnosticHistory",
          key: "diagnostics",
          componentNames: {
            form: "diagnostic-history-form",
            record: "diagnostic-history-info-item",
            print: "diagnostic-history-print",
          },
          api: {
            delete: "/diagnostic/",
          },
        },
        {
          text: "Alergias",
          single: "Alergia",
          icon: "mdi-allergy",
          description: "Alergias",
          value: "allergy",
          key: "allergies",
          componentNames: {
            form: "allergy-form",
            record: "allergy-info-item",
            print: "allergy-print",
          },
          api: {
            delete: "/allergy/",
          },
        },
        {
          text: "Antec. quirúrgicos",
          description: "Antecedentes quirúrgicos",
          single: "Antecedente quirúrgico",
          icon: "mdi-wheelchair-accessibility",
          value: "surgery",
          key: "surgeries",
          componentNames: {
            form: "surgery-form",
            record: "surgery-info-item",
            print: "surgery-print",
          },
          api: {
            delete: "/surgery/",
          },
        },
      ],
    },
    {
      code: '-4',
      description: "Antecedentes gineco-obstétricos",
      color: "pink",
      icon: "mdi-gender-female",
      tabs: [
        {
          text: "Antec. gineco-obstétricos",
          description: "Antecedentes gineco-obstétricos",
          single: "Antecedente gineco-obstétrico",
          icon: "mdi-gender-female",
          value: "gynecology",
          key: "gynecology",
          componentNames: {
            form: "gynecology-form",
            record: "gynecology-info-item",
            print: "gynecology-print",
          },
          api: {
            delete: "/gynecology/",
          },
        },
      ],
    },
    {
      code: '-5',
      description: "Evaluación médica",
      color: "secondary",
      icon: "mdi-human-male-height",
      tabs: [
        {
          text: "Evaluación médica",
          description: "Evaluación médica",
          single: "Evaluación médica",
          icon: "mdi-human-male-height",
          value: "evaluation",
          key: "evaluations",
          componentNames: {
            form: "medical-evaluation-form",
            record: "medical-evaluation-info-item",
            print: "medical-evaluation-print",
          },
          api: {
            delete: "/medicalevaluation/",
          },
        },
      ],
    },
    {
      code: '-6',
      description: "Diagnóstico",
      color: "secondary",
      icon: "mdi-clipboard-pulse-outline",
      tabs: [
        {
          text: "Diagnóstico",
          description: "Diagnóstico",
          single: "Diagnóstico",
          icon: "mdi-clipboard-pulse-outline",
          value: "diagnostic",
          key: "diagnostics",
          componentNames: {
            form: "diagnostic-form",
            record: "diagnostic-info-item",
            print: "diagnostic-print",
          },
          api: {
            delete: "/diagnostic/",
          },
        },
      ],
    },
    {
      code: '-7',
      description: "Pruebas diagnósticas",
      color: "secondary",
      icon: "mdi-flask",
      tabs: [
        {
          text: "Pruebas diagnósticas",
          description: "Pruebas diagnósticas",
          single: "Prueba diagnóstica",
          icon: "mdi-flask",
          value: "labResult",
          key: "labResults",
          componentNames: {
            form: "lab-result-form",
            record: "lab-result-info-item",
            print: "lab-result-print",
          },
          api: {
            delete: "/labresult/",
          },
        },
      ],
    },
    {
      code: '-8',
      description: "Prescripción de medicamentos",
      color: "secondary",
      icon: "mdi-pill",
      tabs: [
        {
          text: "Prescripción de med.",
          description: "Prescripción de medicamentos",
          single: "Prescripción de medicamentos",
          icon: "mdi-pill",
          value: "medication",
          key: "medications",
          componentNames: {
            form: "medication-form",
            record: "medication-info-item",
            print: "medication-print",
          },
          api: {
            delete: "/medication/",
          },
        },
      ],
    },
    {
      code: '-9',
      description: "Riesgo cardiovascular",
      color: "secondary",
      icon: "mdi-heart",
      tabs: [
        {
          text: "Riesgo cardiovascular",
          description: "Riesgo cardiovascular",
          single: "Riesgo cardiovascular",
          icon: "mdi-heart",
          value: "cardioanalysis",
          key: "cardioAnalysis",
          componentNames: {
            form: "cardio-analysis-form",
            record: "cardio-analysis-info-item",
            print: "cardio-analysis-print",
          },
          api: {
            delete: "/cardioanalysis/",
          },
        },
      ],
    },
  ],
};

const getters = {
  alert: (state) => state.alert,
  warning: (state) => state.warning,
  from: (state) => state.from,
  to: (state) => state.to,
  loadingOverlay: (state) => state.loadingOverlay,
  subForms: (state) => state.subForms,
};

const mutations = {
  setAlert: (state, alertData) => (state.alert = alertData),
  setWarning: (state, warningData) => (state.warning = warningData),
  setFromDate: (state, date) => (state.from = date),
  setToDate: (state, date) => (state.to = date),
  setLoadingOverlay: (state, status) => (state.loadingOverlay = status),
};

export default {
  state,
  getters,
  mutations,
};
