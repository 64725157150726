import axios from "axios";

const state = {
  dateFilter: "",
  medicalHistory: {
    allergies: [],
    diagnostics: [],
    families: [],
    gynecology: [],
    evaluations: [],
    medications: [],
    activities: [],
    toxicHabits: [],
    appointments: [],
    labResults: [],
    surgeries: [],
  },
  iconField: {
    pathology: "mdi-iv-bag",
    observation: "mdi-clipboard-text",
  },
  currentPatient: null,
  loadingMedicalHistory: false,
  generalIsFetched: false,
  consultIsFetched: false,
};

const getters = {
  medicalHistory: (state) => state.medicalHistory,
  iconField: (state) => state.iconField,
  dateFilter: (state) => state.dateFilter,
  loadingMedicalHistory: (state) => state.loadingMedicalHistory,
  generalIsFetched: (state) => state.generalIsFetched,
  consultIsFetched: (state) => state.consultIsFetched,
};

const actions = {
  async fetchGeneralHistory({ commit }, patientID) {
    try {
      commit("setLoadingMedicalHistory", true);
      const res = await axios.get(`/api/patient/general/${patientID}`);

      const generalHistory = res.data.medicalHistory;

      generalHistory.families = generalHistory.families.sort(
        (a, b) => b.id - a.id
      );
      generalHistory.activities = generalHistory.activities.sort(
        (a, b) => b.id - a.id
      );
      generalHistory.toxicHabits = generalHistory.toxicHabits.sort(
        (a, b) => b.id - a.id
      );
      generalHistory.diagnostics = generalHistory.diagnostics.sort(
        (a, b) => b.id - a.id
      );
      generalHistory.allergies = generalHistory.allergies.sort(
        (a, b) => b.id - a.id
      );
      generalHistory.surgeries = generalHistory.surgeries.sort(
        (a, b) => b.id - a.id
      );
      generalHistory.gynecology = generalHistory.gynecology.sort(
        (a, b) => b.id - a.id
      );

      commit("setLoadingMedicalHistory", false);
      commit("setMedicalHistory", generalHistory);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchConsultHistory({ commit }, patientID) {
    try {
      commit("setLoadingMedicalHistory", true);
      const res = await axios.get(`/api/patient/consult/${patientID}`);

      const consultHistory = res.data.medicalHistory;

      consultHistory.evaluations = consultHistory.evaluations.sort(
        (a, b) => b.id - a.id
      );
      consultHistory.appointments = consultHistory.appointments.sort(
        (a, b) => b.id - a.id
      );
      consultHistory.medications = consultHistory.medications.sort(
        (a, b) => b.id - a.id
      );
      consultHistory.labResults = consultHistory.labResults.sort(
        (a, b) => b.id - a.id
      );

      commit("setLoadingMedicalHistory", false);
      commit("setMedicalHistory", consultHistory);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPatientGeneral({ commit }, patientID) {
    try {
      commit("setLoadingMedicalHistory", true);
      const res = await axios.get(`/api/patient/custom/${patientID}`);

      const patientGeneral = res?.data?.items || [];

      // Grouping by the 'code' property
      const groupedData = patientGeneral.reduce((result, item) => {
        const code = item.code;
        if (!result[code]) {
          result[code] = [];
        }
        result[code].push(item);
        return result;
      }, {});

      commit("setLoadingMedicalHistory", false);
      commit("setMedicalHistory", groupedData);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setDateFilter: (state, date) => (state.dateFilter = date),
  resetMedicalHistory: (state, data) => (state.medicalHistory = data),
  setMedicalHistory: (state, data) =>
    (state.medicalHistory = { ...state.medicalHistory, ...data }),

  setLoadingMedicalHistory: (state, status) =>
    (state.loadingMedicalHistory = status),
  pushToMedicalHistory: (state, { item, field }) => {
    if (!state.medicalHistory[field]) {
      let newMedicalHistoryObj = {};
      newMedicalHistoryObj[field] = [item];
      mutations.setMedicalHistory(state, newMedicalHistoryObj);
    } else {
      state.medicalHistory[field].unshift(item);
    }
  },
  updateFromMedicalHistory: (state, { item, field }) => {
    const index = state.medicalHistory[field].findIndex(
      (e) => e.id === item.id
    );
    Object.assign(state.medicalHistory[field][index], item);
  },
  removeFromMedicalHistory: (state, { item, field }) => {
    const newList = state.medicalHistory[field].filter((e) => {
      return e.id !== item.id;
    });
    state.medicalHistory[field] = newList;
  },
  setGeneralStatus: (state, status) => (state.generalIsFetched = status),
  setConsultStatus: (state, status) => (state.consultIsFetched = status),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
