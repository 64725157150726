import axios from "axios";

const state = {
  arss: [],
};

const getters = {
  arss: (state) => state.arss,
};

const actions = {
  async fetchArs({ commit }, queryParams) {
    try {
      const res = await axios.get("/api/ars", { params: queryParams });
      commit("setArs", res.data.ars);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setArs: (state, data) => (state.arss = data),
  pushArs: (state, ars) => state.arss.push(ars),
  updateArs: (state, ars) => {
    const index = state.arss.findIndex((s) => s.id === ars.id);
    Object.assign(state.arss[index], ars);
  },
  removeArs: (state, ars) => {
    state.arss = state.arss.filter((s) => {
      return s.id !== ars.id;
    });
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
