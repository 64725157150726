<template>
  <div>
    <v-tooltip v-if="!invoice" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          @click="clickBanner('show-create-form')"
          small
        >
          <span>
             <span>Pendiente</span> <span class="d-md-none"> de facturar</span>
          </span>
        </v-chip>
      </template>
      <span class="caption">Facturar</span>
    </v-tooltip>

    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          @click="clickBanner('show-info')"
          :color="isPaid ? 'success' : 'error'"
          small
        >
          <span v-if="isPaid">
            <span class="d-md-none">Pago</span> <span>Completado</span>
          </span>
          <span v-else class="font-weight-medium">
            <span class="d-md-none">Pago</span> <span>Incompleto</span>
          </span>
        </v-chip>
      </template>
      <span class="caption">Ver info.</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "InvoiceBanner",
  props: ["invoice", "clickable"],
  methods: {
    clickBanner(eventName) {
      if (!this.clickable) {
        return false;
      }

      this.$emit(eventName);
    },
  },
  computed: {
    isPaid() {
      return (
        parseFloat(this.invoice.totalPaid).toFixed(2) ===
        parseFloat(this.invoice.total).toFixed(2)
      );
    },
  },
};
</script>
