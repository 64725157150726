import axios from "axios";

const state = {
  financeStatsQueryParams: null,
};

const getters = {
  financeStatsQueryParams: (state) => state.financeStatsQueryParams,
};

const actions = {
  async fetchFinanceStats({ commit }, params) {
    try {
      commit("setFinanceStatsQueryParams", params);
      const { data } = await axios.get(`/api/finance/stats`, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  refreshFinanceStats({ dispatch, getters }) {
    dispatch("fetchFinanceStats", getters.financeStatsQueryParams);
  },
};

const mutations = {
  setFinanceStatsQueryParams: (state, data) =>
    (state.financeStatsQueryParams = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
