var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[(_vm.icon)?_c('v-avatar',{attrs:{"slot":"icon","color":_vm.iconBg,"size":"35","dark":""},slot:"icon"},[_c('v-icon',{attrs:{"color":_vm.iconColor,"size":"20"}},[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c('div',{class:_vm.icon ? 'ml-3' : ''},[(_vm.label)?_c('div',{class:`info-item-label caption  ${
        _vm.off
          ? `text-decoration-line-through  ${_vm.labelColor}--text`
          : _vm.important
          ? `font-weight-bold ${_vm.labelColor}--text`
          : `${_vm.labelColor}--text`
      }`},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(!_vm.hideVal)?_c('div',[(!_vm.val && _vm.val !== 0 && _vm.chipVals.length === 0)?_c('span',{class:`info-item-value subtitle-1  light_secondary--text ${
          _vm.off ? 'text-decoration-line-through' : ''
        }`},[_vm._v("-")]):(_vm.chipVals.length > 0)?_c('span',{class:`info-item-value subtitle-1 ${
          _vm.off ? 'text-decoration-line-through pasive--text' : ''
        }`},_vm._l((_vm.chipVals),function(chip,index){return _c('v-chip',{key:index,staticClass:"ml-1",staticStyle:{"font-size":"13px","font-weight":"500"},attrs:{"color":chip.color,"small":"","dark":""}},[_vm._v(" "+_vm._s(chip.text)+" ")])}),1):_c('span',{class:`info-item-value subtitle-1  ${
          _vm.off
            ? 'text-decoration-line-through pasive--text'
            : _vm.important
            ? 'font-weight-bold'
            : ''
        }`},[_vm._v(_vm._s(_vm.val || "-")+" ")])]):_vm._e(),_vm._t("val")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }