<template>
  <div>
    <v-fade-transition v-if="currentLocation">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="d-none d-sm-flex">
            <v-btn
              text
              small
              block
              color="secondary"
              v-bind="attrs"
              v-on="on"
              v-if="currentUser"
            >
              <v-icon small class="mr-1" color="secondary">
                mdi-map-marker
              </v-icon>
              <span style="text-transform: capitalize">
                {{ currentLocation.name }}</span
              >
            </v-btn>
          </div>

          <v-btn text small class="d-flex d-sm-none" v-bind="attrs" v-on="on">
            <v-icon class="ml-1"> mdi-map-marker </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(location, index) in locationsOptions"
            :key="index"
            @click="clickLocationItem(location)"
            color="primary"
          >
            <v-list-item-content
              class="subtitle-2 font-weight-regular"
              style="cursor: pointer"
            >
              {{ location.name }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "LocationSelect",
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "locations", "currentLocation"]),
    locationsOptions() {
      if (this.showAll) {
        return [{ id: -1, name: "Todas las sedes" }, ...this.locations];
      }
      return this.locations;
    },
  },
  methods: {
    ...mapMutations(["setCurrentLocation"]),
    clickLocationItem(location) {
      this.setCurrentLocation(location);
    },
    setLocation() {
      let oldLocation = localStorage.getItem("last-location-selected");
      let location = localStorage.getItem("last-location-select");

      if (oldLocation && oldLocation !== "undefined") {
        oldLocation = JSON.parse(oldLocation);
      }

      if (location && location !== "undefined") {
        location = JSON.parse(location);
      }

      const locationExist = this.locationsOptions.filter(
        (l) => l.id == location?.id
      )[0]
        ? true
        : false;

      const oldLocationExist = this.locationsOptions.filter(
        (l) => l.id == oldLocation?.id
      )[0]
        ? true
        : false;

      let currentLocation = this.locationsOptions[0];

      if (location && location.id != -1 && locationExist) {
        currentLocation = location;
      } else if (oldLocation && oldLocation.id != -1 && oldLocationExist) {
        currentLocation = oldLocation;
      }

      this.setCurrentLocation(currentLocation);
    },
  },
  watch: {
    currentLocation: function (value, oldValue) {
      localStorage.setItem("last-location-selected", JSON.stringify(oldValue));
      localStorage.setItem("last-location-select", JSON.stringify(value));
    },
    $route: {
      handler(to) {
        this.showAll = to.path !== "/agenda";
        this.setLocation();
      },
      immediate: true,
    },
  },
};
</script>
